import React, { useState } from "react"
import { Autocomplete, Grid, Skeleton, TextField, Typography } from "@planckdata/react-components"
import { ElementComponentProps, makeStyles } from "@planckdata/react-components"
import clsx from "clsx"
import { SagaBetaIcon } from "@planckdata/react-components/components/icons"
import { useTranslation } from "../../../i18n"
import SearchIcon from "@material-ui/icons/Search"
import { useHistory } from "react-router"
import * as colors from "../../../colors"

export interface HeaderProps extends ElementComponentProps<HTMLDivElement> {
  businessName: string
  businessAddress: string
  userFilter: string | null
  allFilters: []
  setUserFilter: (newFilter: string | null) => void
  search: () => void
}

const useStyles = makeStyles(() => ({
  headerContainer: {
    height: "100%",
  },
  logo: {
    cursor: "pointer",
    width: 130,
    height: 33,
    color: colors.primary,
  },
  inputsContainer: {
    marginLeft: 20,
  },
  input: {
    border: `1px solid ${colors.inputBorder}`,
    padding: "15px 20px",
    color: colors.disable,
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    height: 50,
    userSelect: "none",
  },
  leftInput: {
    borderRadius: "25px 0 0 25px",
    backgroundColor: colors.headerLeftInput,
  },
  rightInput: {
    marginLeft: "-1px",
    borderRadius: "0 25px 25px 0",
    display: "flex",
    alignItems: "center",
    "&.open": {
      borderTopRightRadius: 25,
      borderBottomRightRadius: 0,
    },
  },
  searchIcon: {
    height: 48,
    width: 48,
    padding: "12px",
    color: colors.primary,
    border: `1px solid ${colors.inputBorder}`,
    borderRadius: "100%",
    marginLeft: "30px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: colors.hoveredSearchIcon,
      border: `1px solid ${colors.hoveredSearchIconBorder}`,
    },
  },
  singleOption: {
    letterSpacing: "1.68%",
    height: "48px",
    display: "flex",
    alignItems: "center",
  },
  optionsInput: {
    padding: "0 10px",
    margin: "0 -19px",
    width: "calc(99% + 38px)",
  },
  optionsContainer: {
    padding: "0 !important",
  },
  optionsWrapper: {
    marginTop: "9px",
    width: "calc(100% + 8px)",
    borderRadius: "0 !important",
  },
}))

export const Header: React.FC<HeaderProps> = ({
  businessName,
  businessAddress,
  allFilters,
  setUserFilter,
  search,
  userFilter,
}) => {
  const classes = useStyles()
  const [isAutoCompleteOpen, setIsAutoCompleteOpen] = useState(false)
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Grid container direction={"row"} alignItems={"center"} className={classes.headerContainer}>
      <SagaBetaIcon className={classes.logo} onClick={() => history.push("/")} />
      <Grid item xs={6} xl={5}>
        <Grid container className={classes.inputsContainer} direction={"row"}>
          <Grid item xs={4}>
            <Typography className={clsx(classes.input, classes.leftInput)} size={18} lineHeight={15}>
              {`${businessName}, ${businessAddress}`}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            {allFilters.length === 0 ? (
              <Grid className={clsx(classes.input, classes.rightInput)}>
                <Skeleton
                  width={"130%"}
                  height={50}
                  style={{ borderBottomLeftRadius: "0 !important", margin: "0 -15%" }}
                />
              </Grid>
            ) : (
              <Autocomplete
                className={clsx(classes.input, classes.rightInput, { open: isAutoCompleteOpen })}
                options={allFilters}
                value={userFilter}
                onOpen={() => setIsAutoCompleteOpen(true)}
                onClose={() => setIsAutoCompleteOpen(false)}
                classes={{
                  // inputRoot: classes.optionsInput,
                  // listbox: classes.optionsContainer,
                  paper: classes.optionsWrapper,
                }}
                onChange={(_event, newValue) => {
                  setUserFilter(newValue ? newValue.toString() : "")
                }}
                renderOption={(optionText) => (
                  <Typography className={classes.singleOption} size={16} lineHeight={24} weight={500}>
                    {optionText}
                  </Typography>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t("header.filter_placeholder")}
                    InputProps={{ ...params.InputProps, disableUnderline: true }}
                    InputLabelProps={params.InputLabelProps}
                  />
                )}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <SearchIcon onClick={() => search()} className={classes.searchIcon} />
    </Grid>
  )
}

export default Header
