import React from "react"
import { Grid, makeStyles, Skeleton, Typography } from "@planckdata/react-components"
import LocationIcon from "../../../../assets/location-marker.png"
import GreyLocationIcon from "../../../../assets/grey-location-marker.png"
import { POIType, POI, LatLon } from "@planckdata/api"
import { useTranslation } from "../../../../i18n"
import clsx from "clsx"
import { poisIcons } from "../../../../helpers/poisHelpers"
import random from "lodash/random"
import range from "lodash/range"
import * as colors from "../../../../colors"

export interface MapLocationDescriptionProps {
  businessName: string
  businessAddress?: string
  pois: Array<POI>
  setHoveredDistancePoi: (hoveredPoi: POI | null) => void
}

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: colors.mapPage,
  },
  dataWrapper: {
    height: "70%",
    width: "100%",
  },
  dataContainer: {
    padding: "10%",
    height: "100%",
    width: "100%",
  },
  dataTitle: {
    width: "100%",
    height: "40px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    flexShrink: 0,
  },
  dataBody: {
    flexShrink: 1,
    overflowY: "auto",
  },
  location: {
    marginTop: 25,
  },
  enteredLocation: {
    marginTop: 20,
  },
  locationText: {
    width: "calc(100% - 30px)",
    paddingLeft: "10px",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  enteredLocationText: {
    color: colors.enteredLocationText,
  },
  imageWrapper: {
    height: "30%",
    width: "100%",
  },
  locationIcon: {
    objectFit: "contain",
    height: "30px",
    width: "30px",
    marginTop: 5,
  },
  greyLocationIcon: {
    objectFit: "contain",
    paddingTop: 5,
    height: "30px",
    width: "30px",
  },
  separator: {
    width: "100%",
    height: "1px",
    backgroundColor: colors.separator,
    margin: "10px 0",
  },
  distanceRow: {
    height: "30px",
    marginTop: "10px",
    width: "auto",
    cursor: "pointer",
    "&:hover": {
      "& $distanceTitle": {
        fontWeight: "600",
      },
      "& $distance": {
        fontWeight: "600",
      },
    },
  },
  distanceIcon: {
    height: "80%",
    marginRight: "5px",
  },
  distanceTitle: { margin: "0 5px" },
  distance: {},
}))

export const MapLocationDesctiption: React.FC<MapLocationDescriptionProps> = ({
  businessName,
  businessAddress,
  pois,
  setHoveredDistancePoi,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const mainAddress = pois.find((singlePoi) => singlePoi.type === POIType.MAIN_ADDRESS)

  function calculateDistance(mk1: LatLon, mk2: LatLon, parseToKm = false) {
    const R = 3958.8 // Radius of the Earth in miles
    const rlat1 = parseFloat(mk1.lat) * (Math.PI / 180) // Convert degrees to radians
    const rlat2 = parseFloat(mk2.lat) * (Math.PI / 180) // Convert degrees to radians
    const difflat = rlat2 - rlat1 // Radian difference (latitudes)
    const difflon = (parseFloat(mk2.lon) - parseFloat(mk1.lon)) * (Math.PI / 180) // Radian difference (longitudes)

    const d =
      2 *
      R *
      Math.asin(
        Math.sqrt(
          Math.sin(difflat / 2) * Math.sin(difflat / 2) +
            Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2),
        ),
      )
    return parseToKm ? d * 1.6 : d
  }

  function getPoisDistance() {
    if (!mainAddress?.address) {
      return range(3).map((index) => (
        <Skeleton
          height={33}
          width={`${random(45, 65)}%`}
          style={{ marginTop: "10px" }}
          key={`distance-skeleton-${index}`}
          data-testid={`map-description-distance-skeleton-${index}`}
        />
      ))
    }

    return pois.map((singlePoi, index) =>
      singlePoi.type === POIType.MAIN_ADDRESS ? null : (
        <Grid
          container
          direction={"row"}
          className={classes.distanceRow}
          alignItems={"center"}
          key={`distance-row-${index}`}
          onClick={() => {
            setHoveredDistancePoi(singlePoi)
            setTimeout(() => setHoveredDistancePoi(null), 2000)
          }}
        >
          <img className={classes.distanceIcon} src={poisIcons[singlePoi.type]} />
          <Grid item className={classes.distanceTitle}>
            {singlePoi.type}:
          </Grid>
          <Grid item className={classes.distance}>
            {calculateDistance(singlePoi.location, mainAddress?.location).toFixed(2)} away
          </Grid>
        </Grid>
      ),
    )
  }

  return (
    <Grid container direction={"column"} className={classes.container}>
      <Grid item className={classes.dataWrapper}>
        <Grid container direction={"column"} className={classes.dataContainer} wrap="nowrap">
          <Typography className={classes.dataTitle} size={32} lineHeight={36} weight={"bold"}>
            {businessName}
          </Typography>
          <Grid item className={classes.dataBody}>
            <Grid container direction={"row"} className={classes.location}>
              <img src={LocationIcon} className={classes.locationIcon} alt={""} />
              <Typography className={classes.locationText} size={24} lineHeight={33}>
                {mainAddress?.address || (
                  <Skeleton height={33} width={`65%`} data-testid={`map-description-main-address`} />
                )}
              </Typography>
            </Grid>
            <Grid item>
              {businessAddress && (
                <Grid container direction={"row"} className={classes.enteredLocation}>
                  <img src={GreyLocationIcon} className={classes.greyLocationIcon} alt={""} />
                  <Typography
                    className={clsx(classes.locationText, classes.enteredLocationText)}
                    size={18}
                    lineHeight={30}
                  >
                    <span style={{ fontWeight: 600 }}>{t("geo_page.entered_location")}: &ensp;</span>
                    {businessAddress}
                  </Typography>
                </Grid>
              )}
              <Grid container className={classes.separator} />
              {getPoisDistance()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MapLocationDesctiption
