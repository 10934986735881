import React from "react"
import { Grid, makeStyles, Typography } from "@planckdata/react-components"
import PersonIcon from "@material-ui/icons/Person"
import { inputBorder } from "../../../../colors"

export interface SingleReviewProps {
  rating?: number
  author: string
  time_published: number
  title: string
  content: string
  source: string
}

const useStyles = makeStyles(() => ({
  reviewsContainer: {
    width: "100%",
    maxWidth: "864px",
    borderBottom: `1px solid ${inputBorder}`,
    flexShrink: 0,
    paddingTop: 24,
    paddingBottom: 24,
  },
  userIcon: {
    borderRadius: "100%",
    backgroundColor: "#F2F2F2",
    color: "#BDBDBD",
    padding: 8,
    height: 45,
    width: 45,
  },
  sourceText: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "inline-block",
    maxWidth: "100%",
    color: "#a7a5a5",
    marginTop: 8,
  },
  contentText: {
    marginTop: "11px",
  },
}))

export const SingleReview: React.FC<SingleReviewProps> = ({
  rating,
  author,
  // time_published,
  title,
  content,
  source,
}) => {
  const classes = useStyles()
  const TOP_RATING = 5

  function renderStarsRating(rate: number) {
    function getStarColor() {
      if (rate > 4) {
        return "#5F4CFF"
      } else if (rate > 2) {
        return "#FFD200"
      }
      return "#BB002C"
    }

    rate = Math.floor(rate)
    if (rate > 5) {
      rate = 5
    }
    return (
      <Grid container direction={"row"}>
        {[
          ...new Array(rate).fill(<div style={{ color: getStarColor() }}>★</div>),
          ...new Array(TOP_RATING - rate).fill(<div style={{ color: "#DFE6E9" }}>☆</div>),
        ].map((star, index) => (
          <Grid item key={index}>
            {star}
          </Grid>
        ))}
      </Grid>
    )
  }

  function parseSource() {
    if (source.includes("http")) {
      const startOptions = ["https://www.", "http://www.", "https://", "http://"]
      startOptions.forEach((startOption) => (source = source.replace(startOption, "")))
      source = source.substring(0, source.indexOf("/"))
      return source
    }
    return source?.match(/[A-Z][a-z]+|[0-9]+/g)?.join(" ")
  }

  return (
    <Grid container className={classes.reviewsContainer}>
      <Grid item xs={12}>
        <Grid container alignItems={"center"} style={{ height: 50 }}>
          <Grid item xs={1}>
            <PersonIcon className={classes.userIcon} />
          </Grid>
          <Grid item xs={7}>
            <Grid container direction={"column"}>
              <Typography weight={600} size={18}>
                {author}
              </Typography>
              {rating && renderStarsRating(rating)}
            </Grid>
          </Grid>
          <Grid item xs={4} style={{ height: "100%" }}>
            <Grid container justifyContent={"flex-end"}>
              <Typography size={14} className={classes.sourceText}>
                {parseSource()}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.contentText}>
        <Typography size={16} weight={600}>
          {title && `${title}\n`}
        </Typography>
        <Typography size={14}>{content}</Typography>
      </Grid>
    </Grid>
  )
}

export default SingleReview
