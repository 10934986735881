export const fetchWrapper = (
  getResultsMethod: () => Promise<any>,
  setResultsMethod: (response: any) => any,
  fetchCount = 0,
  setError: (error: boolean) => void,
): void => {
  getResultsMethod()
    .then((response: any) => {
      setResultsMethod(response)
    })
    .catch(() => {
      if (fetchCount < 2) {
        setTimeout(() => fetchWrapper(getResultsMethod, setResultsMethod, fetchCount + 1, setError), 2000)
      } else {
        setError(true)
      }
    })
}
