import React from "react"
import { useTranslation } from "../../../../i18n"
import { Grid, makeStyles, Typography } from "@planckdata/react-components"
import { ArrowDownIcon } from "@planckdata/react-components/components/icons"
import clsx from "clsx"
import RelevantSubmit from "./RelevantSubmit"
import { useUser } from "user-context"
import * as colors from "../../../../colors"
import { Routes } from "../../../../router"
import { encryptStr } from "../../../../helpers/helpers"
import useTrackEvents from "helpers/track-events.hook"

export interface SingleResultProps {
  title: string
  description: string
  url: string
  data: { [key: string]: string }
  isUnique: boolean
  sources: []
  index: number
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: "95%",
    maxWidth: "864px",
    padding: "15px 20px",
    marginBottom: "30px",
    borderRadius: "12px",
    boxShadow: "0px 2px 6px rgb(50 50 50 / 0%)",
    "&:hover": {
      boxShadow: "0px 2px 6px rgb(50 50 50 / 14%)",
    },
    transition: theme.transitions.create("box-shadow", {
      easing: theme.transitions.easing.easeInOut,
      duration: 150,
    }),
    "&.unique": {
      backgroundColor: colors.uniqueContainer,
    },
  },
  urlContainer: {
    height: "30px",
  },
  urlTagsInfoContainer: {
    height: "26px",
    marginLeft: "10px",
    borderRadius: "24px",
    background: colors.urlTag,
    padding: "2px 10px",
    maxWidth: "120px",
  },
  urlTagsInfo: {
    letterSpacing: "1px",
    textTransform: "uppercase",
    color: colors.urlTagText,
  },
  urlArrow: {
    transform: "rotate(-90deg)",
    height: "15px",
    width: "15px",
  },
  url: {
    cursor: "pointer",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "calc(100% - 140px)",
  },
  title: {
    color: colors.primary,
    marginTop: "15px",
    cursor: "pointer",
  },
  description: {
    marginTop: 5,
    display: "-webkit-box",
    "-webkit-line-clamp": 4,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  uniqueTitle: {
    letterSpacing: "1px",
    textTransform: "uppercase",
    color: colors.uniqueTitleText,
  },
}))

export const SingleResult: React.FC<SingleResultProps> = ({
  index,
  title,
  description,
  sources,
  url,
  data,
  isUnique,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { trackResultUrlEnter } = useTrackEvents()
  const user = useUser()
  const repoUrl = "XXXX"
  const isRepo = url === repoUrl
  const urlParams = new URLSearchParams(window.location.search)
  const searchId = urlParams.get("searchId") || ""

  function navigateToResult(index: number) {
    trackResultUrlEnter({ user: user.current!, sources, url, isUnique, isRepo, index })

    if (url && url !== "XXXX") {
      window.open(url)
    } else if (data) {
      window.open(
        `${window.location.origin}${Routes.SingleRepo}?searchId=${searchId}&id=${encryptStr(JSON.stringify(data))}`,
      )
    }
  }

  return (
    <Grid container className={clsx(classes.container)}>
      <Grid item xs={12}>
        <Grid container direction={"row"} alignItems={"center"} className={classes.urlContainer}>
          <Grid item xs={12}>
            <Grid container direction={"row"} alignItems={"center"}>
              {!isRepo ? (
                <>
                  <Typography className={classes.url} size={15} lineHeight={22} onClick={() => navigateToResult(index)}>
                    {url}
                  </Typography>
                  <ArrowDownIcon className={classes.urlArrow} />
                </>
              ) : (
                <Grid
                  className={classes.urlTagsInfoContainer}
                  style={{ maxWidth: "none", marginLeft: 0 }}
                  data-testid={`single-result-public-documentation-tag-${index}`}
                >
                  <Typography size={11} weight={600} lineHeight={22} className={classes.urlTagsInfo}>
                    {t("single_result.repo_title_public_documentation")}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Typography size={24} lineHeight={22} className={classes.title} onClick={() => navigateToResult(index)}>
          {/* TODO handle*/}
          {title || "** No title **"}
        </Typography>
      </Grid>

      <Grid item xs={12} className={classes.description}>
        <Typography size={14} lineHeight={22}>
          {/* TODO handle*/}
          {description || "** No description **"}
        </Typography>
      </Grid>

      <RelevantSubmit
        sources={sources}
        isRepo={isRepo}
        isRepoVote={false}
        index={index}
        url={url}
        isUnique={isUnique}
      />
    </Grid>
  )
}

export default SingleResult
