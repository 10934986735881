import React from "react"
import { Grid, makeStyles, Skeleton } from "@planckdata/react-components"
import range from "lodash/range"
import random from "lodash/random"

export interface LoadingSingleResultProps {}

const useStyles = makeStyles(() => ({
  container: {
    width: "95%",
    maxWidth: "864px",
    padding: "15px 20px 15px 20px",
    marginTop: "30px",
    borderRadius: "12px",
    boxShadow: "0px 2px 6px rgb(50 50 50 / 0%)",
    backgroundColor: "rgba(247,247,248,1)",
  },
}))

export const SkeletonSingleResult: React.FC<LoadingSingleResultProps> = (props) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.container} {...props}>
      <Grid item>
        <Grid container direction={"row"}>
          <Skeleton width={250} height={15} />
          {range(random(1, 3)).map((index) => (
            <Skeleton key={index} width={80} height={15} style={{ marginLeft: "10px" }} />
          ))}
        </Grid>
      </Grid>
      <Grid container direction={"row"}>
        <Skeleton width={random(60, 100) + "%"} height={15} style={{ marginTop: "12px" }} />
      </Grid>
      <Grid container direction={"row"}>
        <Skeleton width={random(70, 100) + "%"} height={50} style={{ marginTop: "10px" }} />
      </Grid>
      <Skeleton width={"35%"} height={15} style={{ marginTop: "10px" }} />
    </Grid>
  )
}

export default React.memo(SkeletonSingleResult, () => true)
