import { useEffect, useRef } from "react"

export function useFirstRender(): any {
  const firstRender = useRef(true)
  useEffect(() => {
    firstRender.current = false
  }, [])
  return firstRender.current
}

export function parseDate(date: number): string {
  const parsedDate = new Date(date)
  const dd = String(parsedDate.getDate()).padStart(2, "0")
  const mm = String(parsedDate.getMonth() + 1).padStart(2, "0") //January is 0!
  const yyyy = parsedDate.getFullYear()

  return mm + "/" + dd + "/" + yyyy
}

export function setUrlParam(key: string, value: string | null): void {
  const urlParams = new URLSearchParams(window.location.search)

  if (value) {
    urlParams.set(key, value)
    window.history.replaceState(
      null,
      "Saga",
      window.location.origin + window.location.pathname + "?" + urlParams.toString(),
    )
  } else {
    urlParams.delete(key)
    window.history.replaceState(
      null,
      "Saga",
      window.location.origin + window.location.pathname + "?" + urlParams.toString(),
    )
  }
}

export function encryptStr(str: string): string {
  return Array.from(str)
    .reduce((hash, char) => 0 | (31 * hash + char.charCodeAt(0)), 0)
    .toString()
}
