import React, { useState } from "react"
import { Grid, makeStyles, Popover } from "@planckdata/react-components"
import transparentBackground from "../../../../assets/transparent.png"
import SkeletonSingleImage from "./SkeletonSingleImage"

export interface SingleImageProps {
  url: string
  fetch_date: number
  source: string
  height: number
  width: number
  imageSourceString?: string
  minWidth: number
  maxWidth: number
  imageHeight: number
  onClick?(e: React.MouseEvent<HTMLImageElement>): void
}

const useStyles = makeStyles(() => ({
  singleImageContainer: {
    margin: "7.5px 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  singleImage: {
    height: "100%",
    width: "100%",
    "object-fit": "contain",
    "&:hover": {
      cursor: "pointer",
      opacity: 0.8,
    },
  },
  popoverImage: {
    maxHeight: "90vh",
    maxWidth: "90vw",
    minHeight: "10vh",
    marginBottom: "-5px",
    "object-fit": "contain",
  },
  paper: {
    border: "none",
    boxShadow: "none",
    background: `url(${transparentBackground})`,
    backgroundRepeat: "repeat",
    backgroundSize: "80px 80px",
  },
  popoverBackdrop: {
    backdropFilter: "blur(5px)",
  },
}))

export const SingleImage: React.FC<SingleImageProps> = ({
  height,
  width,
  imageSourceString,
  minWidth,
  maxWidth,
  imageHeight,
  onClick,
}) => {
  const classes = useStyles({ height, width })
  const [isOpen, setIsOpen] = useState<boolean>(false)

  function getImageContainerSize() {
    if (width === 1 || height === 1) {
      return { display: "none" }
    } else if (!width || !width) {
      return { height: `${imageHeight}px`, width: `${minWidth}px` }
    }
    const divider = height / 150
    return {
      height: `${imageHeight}px`,
      width: width / height > 2 ? `${maxWidth}px` : `${width / divider}px`,
    }
  }

  return (
    <Grid container className={classes.singleImageContainer} style={getImageContainerSize()}>
      <Popover
        classes={{ root: classes.popoverBackdrop, paper: classes.paper }}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
        transformOrigin={{ vertical: "center", horizontal: "center" }}
      >
        <img src={"data:image/jpeg;base64," + imageSourceString} className={classes.popoverImage} />
      </Popover>
      {imageSourceString ? (
        <img
          src={"data:image/jpeg;base64," + imageSourceString}
          className={classes.singleImage}
          onClick={(e) => {
            setIsOpen(true)
            onClick?.(e)
          }}
        />
      ) : (
        <SkeletonSingleImage height={`${imageHeight}px`} width={`${width}px`} />
      )}
    </Grid>
  )
}

export default SingleImage
