import React, { useState, useEffect, useRef } from "react"
import { useTranslation } from "../../../i18n"
import {
  IconButton,
  ElementComponentProps,
  Grid,
  LoadingContainer,
  LocationAutocomplete,
  makeStyles,
  TextField,
  Typography,
} from "@planckdata/react-components"
import clsx from "clsx"
import Search from "@planckdata/react-components/mui/icons/Search"
import { SagaAPI } from "@planckdata/api"
import { options as navigationsOptions } from "../ResultsTabsPage/NavigationOptionsHeader"
import * as colors from "../../../colors"
import { ConfirmSearchDialog } from "../../molecules"
import { useHistory } from "react-router"
import SagaBetaLogo from "../../../assets/saga-beta-logo.svg"
import { useUser } from "user-context"
import useTrackEvents from "helpers/track-events.hook"

export interface SearchPageProps extends ElementComponentProps<HTMLDivElement> {
  //
}

const useStyles = makeStyles(() => ({
  input: {
    height: 60,
    padding: "15px 20px",
    width: 250,
    border: `1px solid ${colors.inputBorder}`,
    borderRadius: "32px",
    margin: "0 10px",
    "&.error": {
      borderColor: colors.error,
    },
  },
  addressInput: {
    width: 400,
  },
  submitButton: {
    backgroundColor: colors.primary + "!important",
    borderRadius: "100%",
    width: 60,
    height: 60,
    "&.disabled": {
      backgroundColor: `${colors.disabledSubmitButton} !important`,
    },
  },
  root: {
    width: "100vw",
    height: "100vh",
  },
  logoContainer: {
    color: colors.primary,
    width: 230,
    height: 88,
    marginBottom: 52,
  },
  bottomText: {
    position: "absolute",
    bottom: 20,
    color: colors.foregroundAltText2,
  },
  helperText: {
    color: colors.error,
    marginTop: "20px",
  },
}))

export const SearchPage: React.FC<SearchPageProps> = ({ className, ...props }) => {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const history = useHistory()
  const { trackSearch } = useTrackEvents()
  const businessAddressRef = useRef<HTMLInputElement>(null)
  const user = useUser()

  const [businessName, setBusinessName] = useState("")
  const [businessNameErrorText, setBusinessNameErrorText] = useState<string | null>("")
  const [address, setAddress] = useState("")
  const [addressErrorText, setAddressErrorText] = useState<string | null>("")
  const [loading, setLoading] = useState(false)
  const [showConfirmSearch, setShowConfirmSearch] = useState(false)

  const isValidBusinessName = businessName.length >= 3
  const isValidAddress = address.length > 0
  const isValidInput = isValidBusinessName && isValidAddress
  const requireConfirmation = user.current?.internal === true && user.current.orgType !== "test"

  useEffect(() => {
    setAddressErrorText(null)
  }, [address])

  useEffect(() => {
    setBusinessNameErrorText(null)
  }, [businessName])

  async function onSearch() {
    if (!isValidInput) {
      setAddressErrorText(!isValidAddress ? t("search_page.address.missing_value_error") : null)
      setBusinessNameErrorText(!isValidBusinessName ? t("search_page.business_name.missing_value_error") : null)
    } else if (!loading) {
      if (requireConfirmation) {
        setShowConfirmSearch(true)
      } else {
        await search()
      }
    }
  }

  async function search() {
    setLoading(true)
    setShowConfirmSearch(false)
    try {
      const response = await SagaAPI.postSearch({
        businessName: businessName,
        addressFreeText: address,
      })
      trackSearch(user.current!)
      history.push({
        pathname: "/search",
        search: new URLSearchParams({
          p: navigationsOptions[0].pageRoute,
          name: businessName,
          address: address,
          searchId: response.searchId,
        }).toString(),
      })
    } catch (e) {
      setLoading(false)
      console.error("Could not perform search", e)
      // TODO: display error
    }
  }

  function focusOnAddress() {
    if (businessAddressRef && businessAddressRef.current) {
      businessAddressRef.current.focus()
    }
  }

  return (
    <>
      <Grid
        container
        className={clsx(className, classes.root)}
        justifyContent="center"
        direction="column"
        alignItems="center"
        spacing={3}
      >
        <img src={SagaBetaLogo} className={classes.logoContainer} />
        <Grid item>
          <Grid container direction={"row"} justifyContent={"center"}>
            <TextField
              autoFocus
              required
              disabled={loading}
              value={businessName}
              onChange={(e) => setBusinessName(e.target.value)}
              onKeyPress={(e) => e.code === "Enter" && focusOnAddress()}
              className={clsx(classes.input, { error: businessNameErrorText })}
              placeholder={t("search_page.business_name.placeholder")}
              success={isValidBusinessName}
              InputProps={{ disableUnderline: true }}
              helperText={businessNameErrorText}
              FormHelperTextProps={{
                className: classes.helperText,
              }}
              fullWidth
            />
            <LocationAutocomplete
              onChange={(_e, value) => value?.description && setAddress(value?.description)}
              value={address}
              className={clsx(classes.input, classes.addressInput, { error: businessNameErrorText })}
              disabled={loading}
              TextFieldProps={{
                required: true,
                success: isValidAddress,
                placeholder: t("search_page.address.placeholder"),
                disabled: loading,
                InputProps: {
                  disableUnderline: true,
                  inputRef: businessAddressRef,
                  onKeyPress: (e) => {
                    e.code === "Enter" && onSearch()
                  },
                },
                fullWidth: true,
                helperText: addressErrorText,
                FormHelperTextProps: {
                  className: classes.helperText,
                },
                onChange: (e) => setAddress(e.target.value || ""),
              }}
            />
            {loading ? (
              <LoadingContainer loading={true} />
            ) : (
              <IconButton
                className={clsx(classes.submitButton, { disabled: !isValidInput })}
                type="submit"
                variant="contained"
                onClick={onSearch}
              >
                <Search style={{ color: "white", width: 28, height: 28 }} />
              </IconButton>
            )}
            <Grid item className={classes.bottomText}>
              <Typography size={18 as any}>{t("search_page.bottom_text")}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ConfirmSearchDialog open={showConfirmSearch} onConfirm={search} onCancel={() => setShowConfirmSearch(false)} />
    </>
  )
}

export default SearchPage
