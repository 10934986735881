// This file should include all the setup needed for each package from @planckdata.

import { Namespace } from "@planckdata/i18n"
import i18next from "i18next"

/** Each package's i18n namespace MUST be included here for it to be loaded. */
const i18nNamespaces: Namespace[] = ["react-components", "storage"]

export function loadAllI18nNamespaces(): void {
  for (const ns of i18nNamespaces) {
    i18next.loadNamespaces(ns)
  }
}
