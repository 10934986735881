export const GenerateInsightsBasePath = "/generate-insights"

export enum Routes {
  Home = "/",
  Login = "/login",
  ChangePassword = "/change-password",
  Register = "/sign-up",
  Results = "/search",
  SingleRepo = "/repo",
  PwaSearch = "/pwa/search",
}
