import { LoginFormProps, useTracking } from "@planckdata/react-components"
import { mixpanel } from "analytics"
import React from "react"
import { useHistory } from "react-router"
import { Routes } from "router"
import { useUser } from "user-context"

export type UseLoginResponse = Omit<LoginFormProps, "mode">

export function useLogin(
  loading: boolean,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
): UseLoginResponse {
  const user = useUser()

  React.useEffect(() => {
    user
      .initializeClient({ Auth0ClientConfig: { useRefreshTokens: process.env.NODE_ENV !== "development" } })
      .catch()
      .then(() => setLoading(false))
  }, [])

  const loginProps: UseLoginResponse = {
    onLogin: async (details) => {
      await user.requestLogin(details)
      setLoading(false)
    },
    onRegister: async () => console.warn("Not implemented: Register"),
    onResetPassword: async (details) => {
      const res = await user.requestPasswordChange(details)
      setLoading(false)
      return res
    },
    isLoggedIn: user.isLoggedIn,
    loading: loading,
    routes: {
      home: Routes.Home,
      login: Routes.Login,
      passwordReset: Routes.ChangePassword,
      register: Routes.Register,
    },
  }
  return loginProps
}

export function usePageView(): void {
  const { trackEvent } = useTracking()
  const history = useHistory()
  const user = useUser()

  const trackPageView = React.useCallback(() => {
    if (user.current) {
      mixpanel.identify(user.current ? user.current.username : "guest")
      trackEvent({
        action: "Page View",
        origin: window.location.origin,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.current?.username])

  React.useEffect(() => {
    trackPageView()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    return history.listen(trackPageView)
  }, [history, trackPageView])
}
