/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { POI, POIType } from "@planckdata/api"
import { AnalyticsAction, useTracking } from "@planckdata/react-components"
// import capitalize from "lodash/capitalize"
import { Options } from "react-tracking"
import { User } from "user-context"

export type ResultsTab = "results" | "govt_sources" | "images" | "map" | "reviews"

/**
 * Analytics Events
 */
export default function useTrackEvents(
  trackingData?: Partial<AnalyticsAction> | undefined,
  options?: Partial<Options<AnalyticsAction>> | undefined,
) {
  const { trackEvent, Track } = useTracking(trackingData, options)

  /**
   * Analytics Events
   */
  return new (class TrackEvents {
    Track = Track

    /**
     * Event Name: `SAGA Logo Clicked`
     */
    trackLogoClick(): void {
      trackEvent({ action: "SAGA Logo Clicked" })
    }

    /**
     * Event Name: `SAGA source feedback`
     */
    trackSourceFeedback(data: {
      helpful: boolean
      user: User
      sources: any[]
      url: string
      isUnique: boolean
      isRepo: boolean
      index: number
    }) {
      trackEvent({
        action: "SAGA source feedback",
        feedback: data.helpful ? "Yes" : "No",
        isRelevant: data.helpful,
        organization: data.user?.orgName,
        internal: data.user?.internal,
        sources: data.sources.join(", "),
        url: data.url,
        isUnique: data.isUnique,
        isRepo: data.isRepo,
        index: data.index,
      })
    }

    /**
     * Event Name: `SAGA source clicked`
     */
    trackResultUrlEnter(data: {
      user: User
      sources: any[]
      url: string
      isUnique: boolean
      isRepo: boolean
      index: number
    }) {
      trackEvent({
        action: "SAGA source clicked",
        organization: data.user?.orgName,
        internal: data.user?.internal,
        sources: data.sources.join(", "),
        url: data.url,
        isUnique: data.isUnique,
        isRepo: data.isRepo,
        index: data.index,
        path: window.location.pathname,
        domain: window.location.host,
      })
    }

    /**
     * Event Name: `Is saga helpful`
     */
    trackIsSagaHelpful(data: {
      isHelpful: boolean
      userFeedback: string
      user: User
      insightFilter: string | undefined
    }) {
      trackEvent({
        action: "Is saga helpful",
        isHelpful: data.isHelpful,
        ...(data.isHelpful ? {} : { feedback: data.userFeedback }),
        organization: data.user.orgName,
        internal: data.user.internal,
        insightFilter: data.insightFilter,
        path: window.location.pathname,
        domain: window.location.host,
      })
    }

    /**
     * Event Name: `Repo Relevant vote` | `Relevant vote`
     */
    trackRelevantAction(data: {
      isRelevant: boolean
      user: User
      sources: string[]
      url: string
      isUnique: boolean
      isRepo: boolean
      isRepoVote: boolean
      index: number
    }) {
      trackEvent({
        action: data.isRepoVote ? "Repo Relevant vote" : "Relevant vote",
        isRelevant: data.isRelevant,
        organization: data.user?.orgName,
        internal: data.user?.internal,
        sources: data.sources.join(", "),
        url: data.url,
        isUnique: data.isUnique,
        isRepo: data.isRepo,
        index: data.index,
      })
    }

    /**
     * Event Name: `Search`
     */
    trackSearch(user: User) {
      trackEvent({
        action: "Search",
        organization: user.orgName,
        internal: user.internal,
        path: window.location.pathname,
        domain: window.location.host,
      })
    }

    /**
     * Event Name: `${poi.type} clicked`
     */
    trackClickPOI(poi: POI) {
      const nounMap: Record<POIType, string> = {
        [POIType.HOSPITAL]: "Hospital",
        [POIType.FIRE_STATION]: "Fire Station",
        [POIType.POLICE_STATION]: "Police Station",
        [POIType.MAIN_ADDRESS]: "Main Address",
      }
      trackEvent({ action: `${nounMap[poi.type]} clicked` })
    }

    /**
     * Event Name: `Image clicked`
     */
    trackImageClick(url: string) {
      trackEvent({ action: "Image clicked", url })
    }

    /**
     * Event Name: `Filter`
     */
    trackFilterChange(insightName: string) {
      trackEvent({ action: "Filter", insight: insightName })
    }

    /**
     * Event Name:
     * | `All Results Tab View`
     * | `Governmental Sources Tab View`
     * | `Images Tab View`
     * | `Map Tab View`
     * | `Reviews Tab View`
     * @param tab Current tab being viewed
     * @param totalResults Total number of results
     * @param error Error message if there was an error
     */
    trackResultsTabView(tab: ResultsTab, totalResults: number, filteredResults: number, error?: boolean | any) {
      const map: Record<ResultsTab, string> = {
        results: "All Results",
        govt_sources: "Governmental Sources",
        images: "Images",
        map: "Map",
        reviews: "Reviews",
      }
      trackEvent({
        action: `${map[tab]} Tab View`,
        "Total Results": totalResults,
        "Filtered Results": filteredResults,
        Error: error,
      })
    }
  })()
}
