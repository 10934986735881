import React, { useEffect, useState } from "react"
import { ENV } from "../../../../env"
import { GoogleMap as ReactGoogleMap, LoadScript, Polyline } from "@react-google-maps/api"
import { LatLon, POI, POIType } from "@planckdata/api"
import Marker from "./Marker"
import { makeStyles, CustomComponentProps, Skeleton } from "@planckdata/react-components"
import clsx from "clsx"
import { convertLocationToGoogleLocation } from "../../../../helpers/poisHelpers"
import useTrackEvents from "helpers/track-events.hook"

// eslint-disable-next-line @typescript-eslint/ban-types
export interface GoogleMapProps extends CustomComponentProps<{}> {
  pois: Array<POI>
  hoveredDistancePoi: POI | null
}

const useStyles = makeStyles(() => ({
  root: {
    "& .gm-style iframe + div": {
      border: "none !important",
    },
    "& button.gm-ui-hover-effect": {
      visibility: "hidden",
    },
  },
}))

export const GoogleMap: React.FC<GoogleMapProps> = ({ pois, hoveredDistancePoi, className, ...props }) => {
  const classes = useStyles({ ...props, className })
  const mainAddress = pois.find((singlePoi) => singlePoi.type === POIType.MAIN_ADDRESS)
  const [center, setCenter] = useState<LatLon | undefined>(undefined)
  const { trackClickPOI } = useTrackEvents()

  useEffect(() => {
    setCenter(mainAddress ? mainAddress.location : undefined)
    setTimeout(() => setCenter(undefined), 500)
  }, [pois])

  return (
    <LoadScript
      googleMapsApiKey={ENV.GOOGLE_PLACES_API_KEY}
      loadingElement={<Skeleton height={"100%"} width={"100%"} style={{ borderRadius: "0" }} />}
    >
      <ReactGoogleMap
        mapContainerClassName={clsx(classes.root, className)}
        mapContainerStyle={{ height: `100%`, width: `100%`, border: "none" }}
        center={center ? convertLocationToGoogleLocation(center) : undefined}
        zoom={13}
      >
        {mainAddress && hoveredDistancePoi ? (
          <Polyline
            options={{ strokeOpacity: 0.7, strokeColor: "rgba(95, 76, 255, 1)" }}
            path={[
              convertLocationToGoogleLocation(mainAddress.location),
              convertLocationToGoogleLocation(hoveredDistancePoi.location),
            ]}
          />
        ) : null}
        {pois.map((singlePoi, index) => (
          <Marker key={`${index}/${singlePoi.type}`} poi={singlePoi} onClick={() => trackClickPOI(singlePoi)} />
        ))}
      </ReactGoogleMap>
    </LoadScript>
  )
}

export default GoogleMap
