import React from "react"
import { colors, Typography, makeStyles, Grid } from "@planckdata/react-components"
import { useTranslation } from "../../../i18n"
import cls from "clsx"
import AllResults from "./AllResults/AllResults"
import MapPage from "./MapPage/MapPage"
import ReviewsPage from "./ReviewsPage/ReviewsPage"
import ImagesPage from "./ImagesPage/ImagesPage"
import GovernmentalSourcesPage from "./GovernmentalSourcesPage/GovernmentalSourcesPage"
import { optionBorder } from "../../../colors"

export interface NavigationSingleOption {
  title: string
  component: React.ComponentType<any>
  disabled?: boolean
  pageRoute: string
}

export const options: Array<NavigationSingleOption> = [
  {
    title: "navigation.all.title",
    component: AllResults,
    pageRoute: "all",
  },
  {
    title: "navigation.map.title",
    component: MapPage,
    pageRoute: "map",
  },
  {
    title: "navigation.images.title",
    component: ImagesPage,
    pageRoute: "images",
  },
  {
    title: "navigation.reviews.title",
    component: ReviewsPage,
    pageRoute: "reviews",
  },
  {
    title: "navigation.governmentalSources.title",
    component: GovernmentalSourcesPage,
    pageRoute: "governmentalSources",
  },
]

const useStyles = makeStyles(() => ({
  navigationOptionsContainer: {
    height: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "flex-end",
  },
  option: {
    borderBottom: `3px solid ${optionBorder}`,
    textAlign: "center",
    padding: "0 20px",
    height: "100%",
    whiteSpace: "nowrap",
    cursor: "pointer",
    "&.selected": {
      borderColor: colors.primary,
    },
    "&.first": {
      paddingLeft: "20px",
    },
    "&.disabled": {
      color: colors.disable,
      cursor: "default",
    },
  },
}))

export interface NavigationOptionsHeaderProps {
  currentNavigation: NavigationSingleOption
  setCurrentNavigation: (newCurrentNavigation: NavigationSingleOption) => void
}

export const NavigationOptionsHeader: React.FC<NavigationOptionsHeaderProps> = ({
  setCurrentNavigation,
  currentNavigation,
}) => {
  const classes = useStyles({})
  const { t } = useTranslation()

  return (
    <Grid container className={classes.navigationOptionsContainer}>
      {options.map((option, index) => (
        <Typography
          key={option.title}
          className={cls(classes.option, {
            selected: currentNavigation.title === option.title,
            first: index === 0,
            disabled: option.disabled,
          })}
          onClick={() => !option.disabled && setCurrentNavigation(option)}
          size={20}
          weight={600}
          lineHeight={20}
        >
          {t(option.title)}
        </Typography>
      ))}
    </Grid>
  )
}

export default NavigationOptionsHeader
