import React from "react"
import { Logo, Grid, makeStyles, ElementComponentProps } from "@planckdata/react-components"
import { I18nNSContext } from "@planckdata/i18n"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.only("xs")]: {
      padding: 15,
    },
    [theme.breakpoints.up("xs")]: {
      padding: 50,
    },
  },
  formContainer: {
    width: 520,
  },
}))

export const AuthLayout: React.FC<ElementComponentProps<HTMLDivElement>> = ({ children, ...props }) => {
  const classes = useStyles(props)
  return (
    <I18nNSContext.Provider value="react-components">
      <div className={classes.root}>
        <div className={classes.formContainer}>
          <Grid container justifyContent="center">
            <Logo height={20} />
          </Grid>
          {children}
        </div>
      </div>
    </I18nNSContext.Provider>
  )
}

export default AuthLayout
