import React from "react"
import { useTranslation } from "../../../../i18n"
import { colors, Grid, makeStyles, Typography } from "@planckdata/react-components"
import SingleResult from "./SingleResult"
import range from "lodash/range"
import SkeletonSingleResult from "./SkeletonSingleResult"
import ErrorPage from "../../ErrorPage/ErrorPage"
import { SearchResultsWithCount } from "../ResultsPage"
import useTrackEvents, { ResultsTab } from "helpers/track-events.hook"

export interface AllResultsProps {
  results: SearchResultsWithCount | null
  resultsError: boolean | any
  tab: ResultsTab
  loading: boolean
}

const useStyles = makeStyles(() => ({
  resultsWrapper: {
    maxHeight: "100%",
    height: "100%",
    width: "100%",
    minWidth: "850px",
    overflow: "auto",
    paddingLeft: "50px",
  },
  allResultsContainer: {
    width: "100%",
    maxWidth: "1700px",
    maxHeight: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
  },
  descriptionWrapper: {
    width: "30%",
    height: "100%",
  },
  resultsCountTitle: {
    color: colors.foregroundAltText2,
    width: "50%",
    margin: "15px 0 10px 25px",
  },
}))

export const AllResults: React.FC<AllResultsProps> = ({ results, resultsError, loading, tab = "results" }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { trackResultsTabView } = useTrackEvents()

  React.useEffect(() => {
    if (!loading) {
      trackResultsTabView(tab, results?.count || 0, results?.searchResults.length || 0, resultsError)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  if (resultsError) {
    return <ErrorPage />
  }

  return (
    <Grid container className={classes.resultsWrapper}>
      {results === null ? (
        <Grid container className={classes.allResultsContainer}>
          {range(9).map((index) => (
            <SkeletonSingleResult
              key={`single-result-skeleton-${index}`}
              data-testid={`single-result-skeleton-${index}`}
            />
          ))}
        </Grid>
      ) : (
        <>
          <Grid container className={classes.allResultsContainer}>
            <Typography size={15} lineHeight={20} className={classes.resultsCountTitle}>
              {t("result_page.results_title", { count: results.count })}
            </Typography>

            {results.searchResults.map((singleResult: any, index: number) => (
              <SingleResult
                key={`${index}/${singleResult.title}`}
                title={singleResult.title}
                description={singleResult.description}
                url={singleResult.url}
                data={singleResult.data}
                sources={singleResult.sources}
                isUnique={singleResult.isUnique}
                index={index}
              />
            ))}
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default AllResults
