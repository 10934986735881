import React, { useEffect } from "react"
import { hot } from "react-hot-loader/root"
import { BrowserRouter } from "react-router-dom"
import { ThemeProvider, track } from "@planckdata/react-components"
import { observer } from "mobx-react-lite"
import { loadAllI18nNamespaces } from "planck"
import { UserProvider } from "user-context"
import { I18nNSContext } from "@planckdata/i18n"
import { dispatchTrackEvent } from "analytics"
import AppRouter from "./AppRouter"
import AppLoader from "./AppLoader"
import { sagaTheme } from "theme"
import { getHostingEnv } from "@planckdata/typescript-utils"

const urlParams = new URLSearchParams(window.location.search)
const businessName = urlParams.get("name") || ""
const businessAddress = urlParams.get("address") || ""
const searchId = urlParams.get("searchId") || ""

const App: React.FC = track(
  {
    searchId: searchId,
    businessName: businessName,
    businessAddress: businessAddress,
    path: window.location.pathname,
    domain: window.location.host,
    environment: getHostingEnv(),
  },
  { dispatch: dispatchTrackEvent },
)(
  observer(() => {
    useEffect(() => {
      loadAllI18nNamespaces()
    }, [])

    return (
      <I18nNSContext.Provider value="saga">
        <React.Suspense fallback={<AppLoader />}>
          <BrowserRouter>
            <UserProvider>
              <ThemeProvider theme={sagaTheme}>
                <AppRouter />
              </ThemeProvider>
            </UserProvider>
          </BrowserRouter>
        </React.Suspense>
      </I18nNSContext.Provider>
    )
  }),
)

export default hot(App)
