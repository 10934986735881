import React, { useEffect, useState } from "react"
import { Grid, makeStyles, Skeleton, Typography } from "@planckdata/react-components"
import { SearchResults } from "@planckdata/api"
import { fetchWrapper } from "../../../helpers/fetchHelpers"
import { SagaAPI } from "@planckdata/api"
import { SagaBetaIcon } from "@planckdata/react-components/components/icons"
import * as colors from "../../../colors"
import sourceIcon from "../../../assets/source.png"
import RelevantSubmit from "../ResultsTabsPage/AllResults/RelevantSubmit"
import range from "lodash/range"
import random from "lodash/random"
import ErrorPage from "../ErrorPage/ErrorPage"
import { useTranslation } from "../../../i18n"
import { encryptStr, useFirstRender } from "../../../helpers/helpers"
import { SearchResult } from "@planckdata/api/saga/models/search_data"
import _ from "lodash"

export interface SingleRepoResultProps {}

const useStyles = makeStyles(() => ({
  singleRepoResultWrapper: {
    width: "100vw",
    height: "100vh",
    maxWidth: "1500px",
    padding: "15% 50px 0 80px",
  },
  logoContainer: {
    width: 115 * 1.5,
    height: 23 * 1.5,
    color: `${colors.primary}66`,
    position: "absolute",
    left: 30,
    top: 30,
  },
  title: {},
  description: {
    marginTop: 30,
  },
  lastUpdate: {
    marginTop: 30,
    display: "flex",
    flexDirection: "row",
  },
  lastUpdateTitle: {
    fontWeight: "bold",
    marginRight: "10px",
  },
  sourceTitle: {
    fontWeight: "bold",
    marginRight: "10px",
  },
  lastUpdateValue: {},
  source: {
    marginTop: 30,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
  },
  sourceValue: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    height: "22px",
    marginTop: "15px",
  },
  dataWrapper: {
    marginTop: 30,
    borderRadius: "20px",
    backgroundColor: "rgba(242, 242, 242, 0.5)",
    padding: "25px 30px",
    maxHeight: "50%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
  icon: {
    width: 20,
    marginRight: 20,
    objectFit: "contain",
  },
  singleDataRow: {
    marginTop: 30,
    display: "flex",
    flexDirection: "row",
    "&:first-child": {
      marginTop: 0,
    },
  },
  singleDataRowTitle: {
    marginRight: 10,
  },
  singleDataRowValue: {},
  sourceLink: {
    textDecoration: "underline",
    cursor: "pointer",
    fontWeight: 600,
    "&, &:visited": {
      color: colors.foregroundText,
    },
    "&:hover": {
      color: colors.linkHover,
    },
  },
  relevantSubmitWrapper: {
    width: "100%",
    height: "50px",
  },
}))

export const SingleRepoResult: React.FC<SingleRepoResultProps> = () => {
  const classes = useStyles()
  const [results, setResults] = useState<SearchResults | null>(null)
  const [resultsError, setResultsError] = useState<boolean>(false)
  const { t } = useTranslation()

  const urlParams = new URLSearchParams(window.location.search)
  const resultId: string | null = urlParams.get("id")
  const resultIndex: number = getRepoIndex(resultId)

  const searchId = urlParams.get("searchId") || ""
  const { title, description, sources, isUnique, url, data, repoSourceUrl } = results?.searchResults[resultIndex] || {}
  const firstRender = useFirstRender()

  function findResultIndexById(searchResults: Array<SearchResult>, id: string | null) {
    return _.findIndex(
      searchResults,
      (singleSearchResult: SearchResult) =>
        singleSearchResult.data && encryptStr(JSON.stringify(singleSearchResult.data)) === id,
    )
  }

  function getRepoIndex(id: string | null): number {
    if (!results?.searchResults) {
      return -1
    }

    if (!id) {
      setResultsError(true)
      return -1
    }

    const index = findResultIndexById(results?.searchResults || [], id)
    if (index === -1 && !resultsError) {
      setResultsError(true)
    }

    return index
  }

  useEffect(() => {
    if (firstRender) {
      fetchWrapper(() => SagaAPI.getSearchResults(searchId), setResults, 0, setResultsError)
    } else if (!results?.isCompleted) {
      setTimeout(() => fetchWrapper(() => SagaAPI.getSearchResults(searchId), setResults, 0, setResultsError), 2000)
    }
  }, [results])

  if (resultsError) {
    return <ErrorPage />
  }

  return (
    <Grid container direction={"column"} className={classes.singleRepoResultWrapper} wrap={"nowrap"}>
      <SagaBetaIcon className={classes.logoContainer} />

      <Typography className={classes.title} weight={"bold"} size={32}>
        {title ? title : <Skeleton width={450} height={50} />}
      </Typography>

      <Typography className={classes.description} size={16} weight={300}>
        {description ? description : <Skeleton width={900} height={100} />}
      </Typography>

      {/* <Grid item className={classes.lastUpdate}>
        <img src={lastUpdateIcon} className={classes.icon} alt={"lastUpdateIcon"} />
        <Typography className={classes.lastUpdateTitle}>{t("repo_page.last_update_title")}:</Typography>
        {repoLastFetchDate ? parseDate(repoLastFetchDate) : <Skeleton width={290} height={25} />}
      </Grid> */}

      <Grid item className={classes.sourceValue}>
        <img src={sourceIcon} className={classes.icon} alt={"source"} />
        <Typography className={classes.sourceTitle}>{t("repo_page.sources_title")}:</Typography>
        {repoSourceUrl ? (
          <div className={classes.sourceLink} onClick={() => window.open(repoSourceUrl)}>
            {repoSourceUrl}
          </div>
        ) : (
          <Skeleton height={25} width={random(150, 250)} />
        )}
      </Grid>

      <Grid item className={classes.dataWrapper}>
        {data
          ? Object.keys(data).map((dataKey: string, index: number) => (
              <div key={"single-data-row-" + index} className={classes.singleDataRow}>
                <Typography className={classes.singleDataRowTitle}>{`${dataKey}:`}</Typography>
                <Typography className={classes.singleDataRowValue} weight={"bold"}>
                  {data[dataKey]}
                </Typography>
              </div>
            ))
          : range(random(3, 5)).map((index) => (
              <Skeleton key={index} width={random(300, 700)} height={30} style={{ marginTop: "15px" }} />
            ))}
      </Grid>
      {results && results?.searchResults && (
        <Grid item className={classes.relevantSubmitWrapper}>
          <RelevantSubmit
            sources={sources || []}
            isRepo
            isRepoVote
            index={resultIndex}
            url={url || ""}
            isUnique={isUnique || false}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default SingleRepoResult
