import "./env"
import { init } from "@planckdata/i18n"
import { GlobalStyle } from "@planckdata/react-components"
import AppLoader from "components/pages/App/AppLoader"
import i18n from "i18next"
import React from "react"
import ReactDOM from "react-dom"
import App from "./components/pages/App"
import reportWebVitals from "./reportWebVitals"
import { AppStyle } from "theme"

init(i18n)

ReactDOM.render(
  // TODO: removed until @material-ui supports Strict Mode (v5)
  // <React.StrictMode>
  <React.Suspense fallback={<AppLoader />}>
    <GlobalStyle />
    <AppStyle />
    <App />
  </React.Suspense>,
  // </React.StrictMode>,
  document.getElementById("root"),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
