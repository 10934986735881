import React from "react"
import clsx from "clsx"
import {
  Grid,
  PageContentBox,
  Popover,
  Typography,
  Link,
  alpha,
  makeStyles,
  typography,
} from "@planckdata/react-components"
import { initials } from "@planckdata/typescript-utils"
import { useUser } from "user-context"
import { observer } from "mobx-react-lite"
import { useTranslation } from "../../../i18n"
import * as colors from "../../../colors"

const useStyles = makeStyles((theme) => ({
  userContentBoxWrapper: {
    height: "100%",
  },
  circle: {
    borderRadius: "50%",
    textAlign: "center",
    margin: "auto",
  },
  smallCircle: {
    ...typography({ size: 28, lineHeight: 50 }),
    transition: theme.transitions.create("all", {
      duration: 500,
      easing: theme.transitions.easing.easeInOut,
    }),
    width: 50,
    height: 50,
    background: colors.primary,
    color: colors.circleContrastText,
    "&:hover": {
      color: colors.circleContrastText,
    },
  },
  largeCircle: {
    transition: theme.transitions.create("all", {
      duration: 500,
      easing: theme.transitions.easing.easeInOut,
    }),
    width: 80,
    height: 80,
    lineHeight: "80px",
    fontSize: 40,
    background: colors.primary,
    color: colors.circleContrastText,
    border: `1px solid ${alpha(colors.largeCircleBorder, 0.2)}`,
  },
  userIconContainer: {
    borderRadius: 8,
    background: alpha(colors.circleContrastText, 0.8),
  },
  popoverContentBox: {
    background: colors.circleContrastText,
    width: 262,
    padding: 30,
  },
  clickable: {
    cursor: "pointer",
    marginRight: theme.spacing(2),
  },
  popoverText: {
    overflowWrap: "break-word",
  },
  userContentPopoverPaper: {
    boxShadow: "none",
    display: "inline-block",
    marginTop: 10,
  },
  internalUserText: {
    maxWidth: 149,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  userContentPopoverInternal: {
    marginTop: 8,
    marginLeft: 0,
  },
  userContentPopoverRounded: {
    background: colors.userContentPopoverRounded,
    border: `1px solid ${colors.userContentPopoverRoundedBorder}`,
    boxShadow: "0px 10px 11px rgba(187, 187, 187, 0.12)",
    borderRadius: "24px",
  },
  logoutLink: {
    textDecoration: "underline",
    fontWeight: 600,
    "&, &:visited": {
      color: colors.foregroundText,
    },
    "&:hover": {
      color: colors.linkHover,
    },
  },
}))

interface UserIconProps {
  username: string
}

const UserIcon: React.FC<UserIconProps> = ({ username }) => {
  const classes = useStyles()
  return <div className={clsx(classes.circle, classes.smallCircle)}>{initials(username)}</div>
}

interface InternalUserIconProps {
  username: string
  orgName?: string
}

const InternalUserIcon: React.FC<InternalUserIconProps> = ({ username, orgName }) => {
  const classes = useStyles()

  return (
    <Grid container alignItems="center" spacing={4} className={classes.userIconContainer}>
      <Grid item>
        <Typography size={16} weight={600} className={classes.internalUserText}>
          {orgName}
        </Typography>
      </Grid>
      <Grid item>
        <UserIcon username={username} />
      </Grid>
    </Grid>
  )
}

export const UserContentBox: React.FC<any> = observer((props) => {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null)
  const open = Boolean(anchorEl)
  const id = open ? "user-popover" : undefined
  const store = useUser()
  const { t } = useTranslation()
  const classes = useStyles(props)
  const internalUser: boolean = store?.current?.internal == true
  const username = store?.current?.username ?? ""

  function handleClick(event: React.MouseEvent) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  return (
    <Grid container justifyContent={"flex-end"} alignItems="center" className={classes.userContentBoxWrapper}>
      <Grid item className={classes.clickable} onClick={handleClick} hidden={!store?.current?.username}>
        {internalUser ? (
          <InternalUserIcon username={username} orgName={store.current?.orgName} />
        ) : (
          <UserIcon username={username} />
        )}
      </Grid>
      <Grid item>
        <Popover
          classes={{
            paper: clsx(classes.userContentPopoverPaper, internalUser ? classes.userContentPopoverInternal : {}),
          }}
          PaperProps={{
            classes: {
              rounded: classes.userContentPopoverRounded,
            },
          }}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <PageContentBox className={classes.popoverContentBox}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <div className={clsx(classes.circle, classes.largeCircle)}>
                  {initials(store?.current?.username ?? "")}
                </div>
              </Grid>
              <Grid item xs={12} sm={12}>
                <div>
                  <Typography size={13} noWrap>
                    {store?.current?.username}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} hidden={!internalUser} className={classes.popoverText}>
                <Typography size={13} align="center" weight={600}>
                  {store?.current?.orgName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
                <Link
                  classes={{
                    root: classes.logoutLink,
                    visited: classes.logoutLink,
                  }}
                  to="/"
                  onClick={() => store.requestLogout()}
                >
                  {t("header.logout")}
                </Link>
              </Grid>
            </Grid>
          </PageContentBox>
        </Popover>
      </Grid>
    </Grid>
  )
})

export default UserContentBox
