import React from "react"
import { Grid, makeStyles, Typography } from "@planckdata/react-components"
import { Images } from "@planckdata/api"
import { useTranslation } from "../../../../i18n"
import SingleImage from "./SingleImage"
import SkeletonSingleImage from "./SkeletonSingleImage"
import ErrorPage from "../../ErrorPage/ErrorPage"
import isEmpty from "lodash/isEmpty"
import range from "lodash/range"
import random from "lodash/random"
import * as colors from "../../../../colors"
import useTrackEvents from "helpers/track-events.hook"

export interface ImagesPageProps {
  images: Images | null
  businessName: string
  imagesError: boolean | any
  loading: boolean
}

const useStyles = makeStyles(() => ({
  imagesContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "flex-start",
    overflow: "auto",
    padding: "0 65px",
    "&::after": {
      content: "''",
      flex: "auto",
    },
  },
}))

export const ImagesPage: React.FC<ImagesPageProps> = ({ images, loading, businessName, imagesError }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const isLoading = images === null
  const minWidth = 150
  const maxWidth = 400
  const imageHeight = 150
  const { Track, trackImageClick, trackResultsTabView } = useTrackEvents({ page: "Images" })

  const getRandomImageSize = () => {
    return {
      height: `${imageHeight}px`,
      width: `${random(minWidth, maxWidth)}px`,
    }
  }

  React.useEffect(() => {
    if (!loading) {
      trackResultsTabView("images", images?.images.length || 0, images?.images.length || 0, imagesError)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  if (isEmpty(images?.images) && !isLoading) {
    return (
      <Typography size={15} style={{ color: colors.foregroundAltText2, marginTop: 15 }}>
        {t("images_page.no_results_found", { businessName })}
      </Typography>
    )
  }

  if (imagesError) {
    return <ErrorPage />
  }

  return (
    <Track>
      <Grid container className={classes.imagesContainer}>
        {isLoading
          ? range(random(20, 50)).map((index) => (
              <SkeletonSingleImage key={index} testId={`images-single-skeleton-${index}`} {...getRandomImageSize()} />
            ))
          : images?.images.map(({ url, fetch_date, source, height, width, imageSourceString, error }, id) =>
              error ? null : (
                <SingleImage
                  key={`single_image_${id}}`}
                  url={url}
                  fetch_date={fetch_date}
                  source={source}
                  height={height}
                  width={width}
                  imageSourceString={imageSourceString}
                  minWidth={minWidth}
                  maxWidth={maxWidth}
                  imageHeight={imageHeight}
                  onClick={() => trackImageClick(url)}
                />
              ),
            )}
      </Grid>
    </Track>
  )
}

export default ImagesPage
