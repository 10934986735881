import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import {
  makeStyles,
  Grid,
  useTracking,
  track,
  createAnalyticsDispatch,
  initAnalytics,
  Theme,
} from "@planckdata/react-components"
import { GeoResults, SagaAPI, SearchResults } from "@planckdata/api"
import { SagaBetaIcon } from "@planckdata/react-components/components/icons"
import { fetchWrapper } from "../../../helpers/fetchHelpers"
import ErrorPage from "../ErrorPage/ErrorPage"
import clsx from "clsx"
import * as colors from "../../../colors"
import { useFirstRender } from "../../../helpers/helpers"
import MapPage from "../ResultsTabsPage/MapPage/MapPage"
import { SearchResultsWithCount } from "../ResultsTabsPage/ResultsPage"
import { ENV } from "env"
import _mixpanel from "mixpanel-browser"
import { ResultsContainer } from "./ResultsContainer"
import { NoResults } from "./NoResults"
import useTrackEvents from "helpers/track-events.hook"

const INSIGHT_FILTER_MAP_PHRASE = "DistanceToClosest"

const useStyles = makeStyles<Theme, { backColor: string | null }>({
  container: {
    height: "100vh",
    width: "100vw",
    overflow: "hidden",
    backgroundColor: ({ backColor }) => backColor ?? "",
  },
  resultsContainer: {
    width: "100%",
    overflow: "auto",
    display: "flex",
    flexDirection: "row",
    "&.noResults": {
      flexDirection: "column",
      alignItems: "flex-start",
      paddingLeft: "70px",
    },
  },
  headerWrapper: {
    width: "100%",
    height: "40px",
    margin: "10px 10px 5px 10px",
  },
  headerContainer: {
    width: "100%",
    height: "100%",
    paddingLeft: "50px",
  },
  headerText: {
    color: colors.headerText,
    marginLeft: "10px",
  },
  logo: {
    color: colors.primary,
    cursor: "pointer",
    width: 63,
    height: 29,
    position: "absolute",
    right: 8,
    top: 0,
  },
  sagaWasThisHelpfulWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    "&.noResults": {
      height: "auto",
      width: "auto",
      marginTop: "30px",
    },
  },
})

export interface PwaResultsPageProps {}

const appName = "pwa"
const mixpanel = initAnalytics(_mixpanel, appName, ENV.MIXPANEL_API_KEY)
const pwaDispatchTrackEvent = createAnalyticsDispatch(mixpanel, appName)

export const PwaResultsPage: React.FC<PwaResultsPageProps> = track(
  {},
  { dispatch: pwaDispatchTrackEvent },
)(() => {
  const { search } = useLocation()
  const urlParams = new URLSearchParams(search)

  const businessName = urlParams.get("name") || ""
  const searchId = urlParams.get("searchId") || ""
  const insightFilter = urlParams.get("insightFilter") || undefined
  const backColor = decodeURIComponent(urlParams.get("backColor") || "")

  const classes = useStyles({ backColor })

  const { trackLogoClick } = useTrackEvents({ page: "View Single Business", section: "SAGA" })

  const [results, _setResults] = useState<SearchResultsWithCount | null>(null)
  const [error, setError] = useState<boolean>(false)
  const [geoResults, setGeoResults] = useState<GeoResults | null>(null)
  const [geoResultsError, setGeoResultsError] = useState<boolean>(false)

  const setResults = React.useCallback(
    (_results: SearchResults | null) =>
      _setResults(
        _results
          ? {
              ..._results,
              count:
                _results.searchResults.length >= (results?.count ?? 0)
                  ? _results.searchResults.length
                  : results?.count ?? 0,
            }
          : results,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [results?.searchResults.length ?? 0],
  )

  const firstRender = useFirstRender()
  const { getGeoSearchResults } = SagaAPI
  const isMapFilter = insightFilter?.includes(INSIGHT_FILTER_MAP_PHRASE)
  const noResults = !!results && results.searchResults.length === 0

  useEffect(() => {
    if (firstRender) {
      fetchWrapper(() => SagaAPI.getSearchResults(searchId, undefined, insightFilter), setResults, 0, setError)
    } else if (!results?.isCompleted) {
      setTimeout(
        () => fetchWrapper(() => SagaAPI.getSearchResults(searchId, undefined, insightFilter), setResults, 0, setError),
        2000,
      )
    }
  }, [results])

  useEffect(() => {
    if (isMapFilter) {
      if (firstRender) {
        fetchWrapper(() => getGeoSearchResults(searchId), setGeoResults, 0, setGeoResultsError)
      } else if (!geoResults?.isCompleted) {
        setTimeout(() => fetchWrapper(() => getGeoSearchResults(searchId), setGeoResults, 0, setGeoResultsError), 2000)
      }
    }
  }, [geoResults])

  function handleLogoClick() {
    trackLogoClick()
    window.open(getUrl(), "_blank", "noopener")
  }

  function getUrl() {
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.delete("insightFilter")
    return window.location.origin + window.location.pathname.replace("pwa/", "") + "?" + urlParams.toString()
  }

  function renderMap() {
    return (
      <Grid className={classes.resultsContainer}>
        <MapPage
          geoResults={geoResults}
          businessName={businessName}
          geoResultsError={geoResultsError}
          loading={false}
        />
      </Grid>
    )
  }

  function renderResults() {
    return (
      <Grid data-testid="SagaPWAResultsContainer" className={clsx(classes.resultsContainer, { noResults: noResults })}>
        {noResults ? (
          <NoResults emptyReason={results.emptyReason} businessName={businessName} />
        ) : (
          <ResultsContainer results={results ? results.searchResults : null} />
        )}
      </Grid>
    )
  }

  if (error) {
    return <ErrorPage />
  }

  return (
    <Grid container direction="column" className={classes.container}>
      <SagaBetaIcon className={classes.logo} onClick={handleLogoClick} />
      {isMapFilter ? renderMap() : renderResults()}
    </Grid>
  )
})

export default PwaResultsPage
