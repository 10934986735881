import React from "react"
import { Skeleton } from "@planckdata/react-components"

export interface SkeletonSingleImageProps {
  height: string
  width: string
  testId?: string
}

export const SkeletonSingleImage: React.FC<SkeletonSingleImageProps> = ({ height, width, testId }) => {
  return (
    <Skeleton height={height} width={width} style={{ margin: "7.5px 15px", display: "flex" }} data-testid={testId} />
  )
}

export default SkeletonSingleImage
