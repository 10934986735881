import React, { useEffect, useState } from "react"
import { Grid, makeStyles, Skeleton, useTracking } from "@planckdata/react-components"
import GoogleMap from "./GoogleMap"
import MapLocationDesctiption from "./MapLocationDescription"
import { GeoResults, POI } from "@planckdata/api"
import { useUser } from "user-context"
import ErrorPage from "../../ErrorPage/ErrorPage"
import useTrackEvents from "helpers/track-events.hook"

export interface MapPageProps {
  businessName: string
  businessAddress?: string
  geoResults: GeoResults | null
  geoResultsError: boolean | any
  loading: boolean
}

const useStyles = makeStyles(() => ({
  mapResultsWrapper: {
    height: "100%",
    width: "100%",
  },
  description: {
    width: "30%",
    height: "100%",
  },
  map: {
    width: "70%",
    height: "100%",
  },
}))

export const MapPage: React.FC<MapPageProps> = ({
  geoResults,
  businessName,
  businessAddress,
  geoResultsError,
  loading,
}) => {
  const classes = useStyles()
  const { trackResultsTabView } = useTrackEvents()
  const user = useUser()
  const [hoveredDistancePoi, setHoveredDistancePoi] = useState<null | POI>(null)

  React.useEffect(() => {
    if (!loading) {
      trackResultsTabView("map", geoResults?.pois.length || 0, geoResults?.pois.length || 0, geoResultsError)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  if (geoResultsError) {
    return <ErrorPage />
  }

  return (
    <Grid container direction={"column"} className={classes.mapResultsWrapper}>
      <Grid item className={classes.description}>
        <MapLocationDesctiption
          businessName={businessName}
          businessAddress={businessAddress}
          pois={geoResults ? geoResults.pois : []}
          setHoveredDistancePoi={setHoveredDistancePoi}
        />
      </Grid>
      <Grid item className={classes.map}>
        {loading ? (
          <Skeleton height={"100%"} width={"100%"} style={{ borderRadius: "0" }} data-testid={`map-skeleton`} />
        ) : (
          <GoogleMap pois={geoResults ? geoResults.pois : []} hoveredDistancePoi={hoveredDistancePoi} />
        )}
      </Grid>
    </Grid>
  )
}

export default MapPage
