import React, { PropsWithChildren } from "react"
import Header from "../../molecules/Header/Header"
import { ElementComponentProps } from "@planckdata/react-components"

export interface MainLayoutProps
  extends ElementComponentProps<HTMLDivElement, "root" | "body" | "content" | "sidebar" | "sidebarOpen"> {}

export const MainLayout: React.FC<PropsWithChildren<MainLayoutProps>> = ({ children }) => {
  return (
    <>
      <Header />
      {children}
    </>
  )
}

export default MainLayout
