import React from "react"
import { FailReason } from "@planckdata/api"
import { useTranslation } from "../../../i18n"
import { makeStyles, Typography } from "@planckdata/react-components"

export interface NoResultsProps {
  emptyReason?: FailReason
  insightFilter?: string
  businessName: string
}

const useStyles = makeStyles({
  noResultsFound: {
    width: "80%",
    marginTop: 40,
  },
})

export const NoResults: React.FC<NoResultsProps> = (props) => {
  const classes = useStyles(props)
  const { t } = useTranslation()
  const { emptyReason, insightFilter, businessName } = props

  function getNoResultsText() {
    if (emptyReason?.failCode === "3rd_party_api_data") {
      return t("pwa_all_results_page.no_results_found_api")
    } else if (emptyReason?.failCode === "no_data_found") {
      return t("pwa_all_results_page.no_results_found_default", {
        sourcesNum: emptyReason.data,
        insightFilter: insightFilter?.match(/[A-Z][a-z]+|[0-9]+/g)?.join(" "),
      })
    }

    return t("pwa_all_results_page.no_results_found", {
      businessName,
      insightFilter: insightFilter?.match(/[A-Z][a-z]+|[0-9]+/g)?.join(" "),
    })
  }

  return (
    <Typography size={24} lineHeight={36} weight={400} className={classes.noResultsFound}>
      {getNoResultsText()}
    </Typography>
  )
}
