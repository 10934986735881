import React from "react"

import { range } from "lodash"
import { Grid, makeStyles } from "@planckdata/react-components"
import { SearchResult } from "@planckdata/api"
import { SingleResult } from "./SingleResult"
import { SingleResultSkeleton } from "./SingleResultSkeleton"

export interface ResultsContainerProps {
  results: Array<SearchResult> | null
}

const useStyles = makeStyles({
  root: {
    maxHeight: "100%",
    height: "100%",
    width: "100%",
    minWidth: 850,
    maxWidth: "1700px",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
  },
})

export const ResultsContainer: React.FC<ResultsContainerProps> = ({ results, ...props }) => {
  const classes = useStyles(props)

  function renderSkeletons() {
    return range(4).map((index) => (
      <SingleResultSkeleton key={`single-result-skeleton-${index}`} data-testid={`single-result-skeleton-${index}`} />
    ))
  }

  function renderResults() {
    return (
      <>
        {results!.map((singleResult: SearchResult, index: number) => (
          <SingleResult
            key={`${index}/${singleResult.title}`}
            title={singleResult.title}
            description={singleResult.description}
            url={singleResult.url}
            data={singleResult.data}
            sources={singleResult.sources}
            isUnique={singleResult.isUnique}
            index={index}
          />
        ))}
      </>
    )
  }

  return (
    <Grid container className={classes.root}>
      {results === null ? renderSkeletons() : renderResults()}
    </Grid>
  )
}
