import React from "react"
import clsx from "clsx"
import { colors, makeStyles, Typography, typography } from "@planckdata/react-components"
import { ThumbDown, ThumbUp } from "@material-ui/icons"
import { useTranslation } from "../../../i18n"

export interface ResultFeedbackProps {
  onFeedback: (helpful: boolean) => void
  submitted: boolean
  isRepo: boolean
}

const useStyles = makeStyles({
  root: {
    ...typography({ size: 12, lineHeight: 22 }),
    color: colors.foregroundAltText2,
    letterSpacing: 0.2,
    display: "flex",
  },
  text: {
    marginRight: 26,
  },
  feedbackButton: {
    background: "none",
    border: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    color: "#4F4F4F",
    cursor: "pointer",
  },
  feedbackIcon: {
    height: 12,
    width: 14,
    fill: "#E0E0E0",
    marginRight: 6,
  },
  positiveIcon: {
    "&&:hover svg": {
      fill: colors.linkHover,
    },
  },
  negativeIcon: {
    "&&:hover svg": {
      fill: "#DF7E95",
    },
  },
})

export const ResultFeedback: React.FC<ResultFeedbackProps> = ({ onFeedback, submitted, isRepo, ...props }) => {
  const classes = useStyles(props)
  const { t } = useTranslation()

  function renderHelpfulButton() {
    return (
      <button
        {...props}
        className={clsx(classes.feedbackButton, classes.positiveIcon)}
        type="button"
        onClick={() => onFeedback(true)}
      >
        <ThumbUp className={classes.feedbackIcon} id="thumbs-up-icon" />
        <Typography size={14}>{t("search_page.isThisLinkHelpful.yes")}</Typography>
      </button>
    )
  }

  function renderNotHelpfulButton() {
    return (
      <button
        {...props}
        className={clsx(classes.feedbackButton, classes.negativeIcon)}
        type="button"
        onClick={() => onFeedback(false)}
      >
        <ThumbDown className={classes.feedbackIcon} id="thumbs-down-icon" />
        <Typography size={14}>{t("search_page.isThisLinkHelpful.no")}</Typography>
      </button>
    )
  }

  function renderSubmittedMessage() {
    return t("search_page.isThisLinkHelpful.thank_you_for_your_vote")
  }

  function renderButtons() {
    return (
      <>
        <div className={classes.text}>
          {isRepo ? t("search_page.isThisLinkHelpful.repo_title") : t("search_page.isThisLinkHelpful.title")}
        </div>
        {renderHelpfulButton()}
        {renderNotHelpfulButton()}
      </>
    )
  }

  return <div className={classes.root}>{submitted ? renderSubmittedMessage() : renderButtons()}</div>
}
