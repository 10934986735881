import React, { useState } from "react"
import {
  Marker as ReactGoogleMapsMarker,
  MarkerProps as ReactGoogleMapsMarkerProps,
  InfoWindow,
} from "@react-google-maps/api"
import { POI } from "@planckdata/api"
import { poisMarkers, convertLocationToGoogleLocation } from "../../../../helpers/poisHelpers"

export interface MarkerProps extends Omit<ReactGoogleMapsMarkerProps, "position"> {
  poi: POI
}

export const Marker: React.FC<MarkerProps> = ({ poi, ...rest }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)

  return (
    <ReactGoogleMapsMarker
      position={convertLocationToGoogleLocation(poi.location)}
      icon={poisMarkers[poi.type]}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      {...rest}
    >
      {isHovered && (
        <InfoWindow>
          <h4>{poi.type}</h4>
        </InfoWindow>
      )}
    </ReactGoogleMapsMarker>
  )
}

export default Marker
