import React from "react"
import clsx from "clsx"
import random from "lodash/random"

import { alpha, colors, makeStyles, Skeleton } from "@planckdata/react-components"

export interface SingleResultSkeletonProps {}

const useStyles = makeStyles({
  root: {
    marginBottom: 35,
    maxWidth: 548,
    width: 548,
  },
  row: {
    height: 22,
    display: "flex",
  },
  url: {
    background: alpha("#333333", 0.3),
  },
  title: {
    background: alpha(colors.primary, 0.5),
  },
  description: {
    background: alpha("#333333", 0.25),
    marginRight: 6,
  },
  feedback: {
    background: "#E0E0E0",
  },
  feedbackRow: {
    display: "flex",
  },
})

export const SingleResultSkeleton: React.FC<SingleResultSkeletonProps> = ({ ...props }) => {
  const classes = useStyles(props)

  function getRandomParts(maxWidth: number, minParts: number, maxParts: number) {
    let sum = maxWidth
    const e = []
    const partCount = random(minParts, maxParts)
    for (let i = 0; i < partCount; i++) {
      if (sum <= 0) {
        break
      }
      const width = random(100, sum)
      e.push(width)
      sum -= width + 6
    }

    return e
  }

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <Skeleton classes={{ root: classes.url }} height={14} width={random(200, 300)} />
      </div>
      <div className={classes.row}>
        <Skeleton classes={{ root: classes.title }} height={18} width={random(320, 450)} />
      </div>
      <div className={classes.row}>
        {getRandomParts(549, 3, 7).map((w, i) => (
          <Skeleton key={i} classes={{ root: classes.description }} height={14} width={w} />
        ))}
      </div>
      <div className={classes.row}>
        {getRandomParts(490, 3, 8).map((w, i) => (
          <Skeleton key={i} classes={{ root: classes.description }} height={14} width={w} />
        ))}
      </div>
      <div className={clsx(classes.row, classes.feedbackRow)}>
        <Skeleton classes={{ root: classes.feedback }} height={14} width={120} style={{ marginRight: 16 }} />
        <Skeleton classes={{ root: classes.feedback }} height={14} width={40} style={{ marginRight: 6 }} />
        <Skeleton classes={{ root: classes.feedback }} height={14} width={40} />
      </div>
    </div>
  )
}
