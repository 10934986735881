export const primary = "#5F4CFF"
export const error = "#BB002C"
export const disable = "#979797"

export const foregroundText = "#3F4142"
export const foregroundAltText2 = "#828282"
export const linkHover = "#00E1C9"
export const separator = "#E0E0E0"
export const inputBorder = "#E0E0E0"

// Error page
export const alertIcon = "#DF7E95"
export const subtitle = "#6f6f6f"

// Pwa feedback
export const buttonBackground = "#f5f7fd"
export const headerText = "#94999A"
export const containerBorder = "#80808057"

// Search page
export const disabledSubmitButton = "#E0E0E0"

// Map page
export const enteredLocationText = "#4F4F4F"
export const mapPage = "rgba(232, 231, 242, 0.2)"

// Relevant feedback
export const thumbsUpFill = "#00E1C9"
export const thumbsDownFill = "#DF7E95"
export const thumbsDefaultFill = "#E0E0E0"
export const relevantSubmitText = "#4F4F4F"

// User content box
export const largeCircleBorder = "#979797"
export const circleContrastText = "#FFFFFF"
export const userContentPopoverRounded = "#FFFFFF"
export const userContentPopoverRoundedBorder = "#F2F2F2"

// Single result
export const urlTag = "rgba(224, 224, 224, 0.5)"
export const uniqueContainer = "rgba(247,247,248,1)"
export const urlTagText = "#4F4F4F"
export const uniqueTitleText = "#4F4F4F"

// Header
export const headerLeftInput = "rgba(224, 224, 224, 0.2)"
export const hoveredSearchIcon = "rgba(95, 76, 255, 0.05)"
export const hoveredSearchIconBorder = "rgba(95, 76, 255, 0.3)"

// Options header
export const optionBorder = "#E8E7F2"
