import React from "react"
import { Grid, makeStyles, Typography } from "@planckdata/react-components"
import { useTranslation } from "../../../i18n"
import { WarningIcon } from "@planckdata/react-components/components/icons"
import * as colors from "../../../colors"

export interface ErrorPageProps {}

const useStyles = makeStyles(() => ({
  container: {
    margin: "20px 65px",
  },
  alertIcon: {
    color: colors.alertIcon,
    height: 40,
    width: 40,
  },
  title: {
    color: colors.error,
    marginLeft: "20px",
  },
  subtitle: {
    color: colors.subtitle,
    marginTop: 15,
  },
}))

export const ErrorPage: React.FC<ErrorPageProps> = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container direction={"column"} className={classes.container}>
      <Grid item>
        <Grid container direction={"row"}>
          <WarningIcon className={classes.alertIcon} />
          <Typography className={classes.title} size={20}>
            {t("something_went_wrong.title")}
          </Typography>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container direction={"row"}>
          <Typography className={classes.subtitle} size={16}>
            {t("something_went_wrong.sub_title")}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ErrorPage
