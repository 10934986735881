import React from "react"
import { hot } from "react-hot-loader/root"
import { Route, Switch } from "react-router-dom"
import { Routes } from "router"
import AuthLayout from "components/layouts/AuthLayout"
import { LoginForm, LoginFormMode } from "@planckdata/react-components"
import ResultsPage from "../ResultsTabsPage/ResultsPage"
import SearchPage from "../SearchPage"
import PrivateRoute from "./PrivateRoute"
import MainLayout from "components/layouts/MainLayout"
import PwaResultsPage from "../PwaResultsPage/PwaResultsPage"
import SingleRepoPage from "../SingleRepoPage/SingleRepoPage"
import { useLogin, usePageView } from "./router-hooks"

export interface AppRouterProps {}

const AppRouter: React.FC<AppRouterProps> = () => {
  const [loading, setLoading] = React.useState(true)
  const loginProps = useLogin(loading, setLoading)

  usePageView()

  return (
    <Switch>
      <Route
        path={Routes.Login}
        exact
        render={() => (
          <AuthLayout>
            <LoginForm {...loginProps} />
          </AuthLayout>
        )}
      />
      <Route
        path={Routes.Register}
        exact
        render={() => (
          <AuthLayout>
            <LoginForm {...loginProps} mode={LoginFormMode.Register} />
          </AuthLayout>
        )}
      />
      <Route
        path={Routes.ChangePassword}
        exact
        render={() => (
          <AuthLayout>
            <LoginForm {...loginProps} mode={LoginFormMode.ChangePassword} />
          </AuthLayout>
        )}
      />
      <Route
        path="*"
        render={() => (
          <Switch>
            <PrivateRoute
              path={Routes.Results}
              render={() => (
                <MainLayout>
                  <ResultsPage />
                </MainLayout>
              )}
            />
            <PrivateRoute path={Routes.PwaSearch} render={() => <PwaResultsPage />} />
            <PrivateRoute path={Routes.SingleRepo} render={() => <SingleRepoPage />} />
            <PrivateRoute
              path={Routes.Home}
              render={() => (
                <MainLayout>
                  <SearchPage />
                </MainLayout>
              )}
            />
            <Route path="*" render={() => "404"} />
          </Switch>
        )}
      />
    </Switch>
  )
}

export default hot(AppRouter)
