import React, { useState } from "react"
import { useTranslation } from "../../../../i18n"
import { Grid, makeStyles, Typography } from "@planckdata/react-components"
import clsx from "clsx"
import { ThumbDown, ThumbUp } from "@material-ui/icons"
import { useUser } from "user-context"
import * as colors from "../../../../colors"
import useTrackEvents from "helpers/track-events.hook"

export interface RelevantSubmitProps {
  sources: string[]
  isRepo: boolean
  isRepoVote: boolean
  index: number
  url: string
  isUnique: boolean
}

const useStyles = makeStyles(() => ({
  relevantSubmitContainer: {
    marginTop: 15,
    color: colors.foregroundAltText2,
    height: 22,
    textAlign: "center",
  },
  relevantSubmitItem: {
    height: "100%",
    margin: "0 5px",
    cursor: "pointer",
    "&.yes:hover #thumbs-up-icon": {
      fill: colors.thumbsUpFill,
    },
    "&.no:hover #thumbs-down-icon": {
      fill: colors.thumbsDownFill,
    },
  },
  relevantSubmitIcon: {
    width: 18,
    height: 18,
    fill: colors.thumbsDefaultFill,
    margin: "2px 4px 0 4px",
  },
  relevantSubmitOptionText: {
    color: colors.relevantSubmitText,
    borderBottom: `1px solid ${colors.relevantSubmitText}`,
  },
}))

export const RelevantSubmit: React.FC<RelevantSubmitProps> = ({ sources, isRepo, index, url, isUnique }) => {
  const classes = useStyles()
  const [isVoted, setIsVoted] = useState(false)
  const { trackSourceFeedback } = useTrackEvents()
  const { t } = useTranslation()
  const user = useUser()

  function onRelevantVoteSubmit(isRelevant: boolean) {
    setIsVoted(true)
    trackSourceFeedback({ helpful: isRelevant, user: user.current!, sources, url, isUnique, isRepo, index })
  }

  return (
    <Grid item xs={12} className={classes.relevantSubmitContainer}>
      {isVoted ? (
        <Grid container direction={"row"}>
          <Grid item>
            <Typography size={14} lineHeight={22}>
              {t("search_page.isThisLinkHelpful.thank_you_for_your_vote")}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container direction={"row"}>
          <Grid item>
            <Typography size={14} lineHeight={22}>
              {isRepo ? t("search_page.isThisLinkHelpful.repo_title") : t("search_page.isThisLinkHelpful.title")}
            </Typography>
          </Grid>

          <Grid item className={clsx(classes.relevantSubmitItem, "yes")} onClick={() => onRelevantVoteSubmit(true)}>
            <Grid container direction={"row"}>
              <ThumbUp className={classes.relevantSubmitIcon} id={"thumbs-up-icon"} />
              <Typography size={14} className={classes.relevantSubmitOptionText}>
                {t("search_page.isThisLinkHelpful.yes")}
              </Typography>
            </Grid>
          </Grid>

          <Grid item className={clsx(classes.relevantSubmitItem, "no")} onClick={() => onRelevantVoteSubmit(false)}>
            <Grid container direction={"row"}>
              <ThumbDown className={classes.relevantSubmitIcon} id={"thumbs-down-icon"} />
              <Typography size={14} className={classes.relevantSubmitOptionText}>
                {t("search_page.isThisLinkHelpful.no")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default RelevantSubmit
