import React from "react"
import { Grid, makeStyles, Skeleton } from "@planckdata/react-components"
import PersonIcon from "@material-ui/icons/Person"
import random from "lodash/random"

export interface SkeletonSingleReviewProps {}

const useStyles = makeStyles(() => ({
  reviewsContainer: {
    width: "100%",
    maxWidth: "864px",
    borderBottom: "1px solid #E0E0E0",
    flexShrink: 0,
    paddingTop: 24,
    paddingBottom: 14,
  },
  userIcon: {
    borderRadius: "100%",
    backgroundColor: "#F2F2F2",
    color: "#BDBDBD",
    padding: 8,
    height: 45,
    width: 45,
  },
}))

export const SkeletonSingleReview: React.FC<SkeletonSingleReviewProps> = () => {
  const classes = useStyles()

  return (
    <Grid container alignItems={"center"} className={classes.reviewsContainer}>
      <Grid item xs={1}>
        <PersonIcon className={classes.userIcon} />
      </Grid>

      <Grid item xs={7}>
        <Grid container direction={"column"}>
          <Skeleton
            height={`20px`}
            width={`${random(50, 200)}px`}
            style={{ margin: "7.5px 15px", borderRadius: "8px" }}
          />
          <Skeleton height={`${random(25, 60)}px`} width={`${random(400, 600)}px`} style={{ margin: "7.5px 15px" }} />
        </Grid>
      </Grid>

      <Grid item xs={4} style={{ height: "100%" }}>
        <Grid container justifyContent={"flex-end"}>
          <Skeleton height={`20px`} width={`100px`} style={{ margin: "7.5px 15px 0 15px" }} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default React.memo(SkeletonSingleReview, () => true)
