import { CurrentUserResponse } from "@planckdata/api"
import { setupUserContext, User as BaseUser } from "@planckdata/storage"
import { dispatchTrackEvent, initAnalyticsUser } from "./analytics"

export interface SagaUser {
  user_name: string
  online_api_key: string
  org_name: string
  top_insights: any[]
  internal: boolean
  lang: null
  org_info: OrgInfo
}

export interface OrgInfo {
  name: string
  org_type: string
  flags: Flags
}

export interface Flags {
  anonymous_state: boolean
  show_pwa_saga_evidence: boolean
  is_report_statistics: null
  is_pilot: boolean
  show_action_required_sort: boolean
}

export interface User extends BaseUser, Omit<SagaUser, "lang"> {
  lang: string | null
}

export interface SagaUserResponse extends User, Omit<CurrentUserResponse, "lang"> {
  //
}

function mapUser(user: any, response: CurrentUserResponse): User {
  const SagaUser: User = {
    ...user,
    locale: response.lang,
  }

  return SagaUser
}

const { useUser, userStore, UserProvider, UserConsumer } = setupUserContext({
  storeConfig: { mapUser, dispatchTrackEvent, initAnalyticsUser },
})
export { useUser, userStore, UserProvider, UserConsumer }
