import React from "react"
import { SearchResult } from "@planckdata/api"
import AllResults from "../AllResults/AllResults"
import { SearchResultsWithCount } from "../ResultsPage"
import useTrackEvents from "helpers/track-events.hook"

export interface GovernmentalSourcesPageProps {
  results: SearchResultsWithCount
  resultsError: boolean | any
  loading: boolean
}

export const GovernmentalSourcesPage: React.FC<GovernmentalSourcesPageProps> = ({ results, resultsError, loading }) => {
  const { Track } = useTrackEvents({ page: "Governmental Sources" })
  const repoUrl = "XXXX"
  const repoResults: SearchResultsWithCount | null =
    results && results.searchResults
      ? {
          searchResults: results.searchResults.filter((singleResult: SearchResult) => singleResult.url === repoUrl),
          isCompleted: results.isCompleted,
          count: results.searchResults.filter((singleResult: SearchResult) => singleResult.url === repoUrl).length,
        }
      : null

  return (
    <Track>
      <AllResults results={repoResults} resultsError={resultsError} loading={loading} tab="govt_sources" />
    </Track>
  )
}

export default GovernmentalSourcesPage
