import React from "react"
import { Grid, makeStyles, Typography } from "@planckdata/react-components"
import { Reviews } from "@planckdata/api"
import { useTranslation } from "../../../../i18n"
import SingleReview from "./SingleReview"
import range from "lodash/range"
import random from "lodash/random"
import isEmpty from "lodash/isEmpty"
import SkeletonSingleReview from "../ReviewsPage/SkeletonSingleReview"
import ErrorPage from "../../ErrorPage/ErrorPage"
import * as colors from "../../../../colors"
import useTrackEvents from "helpers/track-events.hook"

export interface ReviewsPageProps {
  reviews: Reviews
  businessName: string
  reviewsError: boolean | any
  loading: boolean
}

const useStyles = makeStyles(() => ({
  reviewsContainer: {
    maxHeight: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    overflow: "auto",
    paddingLeft: 64,
  },
}))

export const ReviewsPage: React.FC<ReviewsPageProps> = ({ reviews, businessName, reviewsError, loading }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { trackResultsTabView } = useTrackEvents()

  React.useEffect(() => {
    if (!loading) {
      trackResultsTabView("reviews", reviews?.reviews.length || 0, reviews?.reviews.length || 0, reviewsError)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  if (isEmpty(reviews?.reviews) && !loading) {
    return (
      <Typography size={15} style={{ color: colors.foregroundAltText2, marginTop: 15 }}>
        {t("reviews_page.no_results_found", { businessName })}
      </Typography>
    )
  }

  if (reviewsError) {
    return <ErrorPage />
  }

  return (
    <Grid container className={classes.reviewsContainer}>
      {loading
        ? range(random(7, 12)).map((index) => <SkeletonSingleReview key={index} />)
        : reviews.reviews.map(({ rating, author, time_published, title, content, source }, id) => (
            <SingleReview
              key={id}
              rating={rating}
              author={author}
              time_published={time_published}
              title={title}
              content={content}
              source={source}
            />
          ))}
    </Grid>
  )
}

export default ReviewsPage
