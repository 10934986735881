import { createTheme, planckTheme, ThemeOptions, withStyles } from "@planckdata/react-components"
import merge from "lodash/merge"

export const AppStyle = withStyles({
  "@global": {
    body: {
      overflowX: "hidden",
    },
  },
})(() => null)

export const sagaTheme = createTheme(
  merge(planckTheme, {
    overrides: {
      MuiButton: {
        root: {
          borderRadius: 6,
        },
      },
      MuiPaper: {
        root: {
          borderRadius: 6,
        },
      },
    },
  } as ThemeOptions),
)
