import React, { useState, useEffect } from "react"
import { colors, makeStyles, Typography } from "@planckdata/react-components"
import { Grid } from "@planckdata/react-components"
import Header from "./Header"
import NavigationOptionsHeader, {
  options as navigationsOptions,
  NavigationSingleOption,
} from "./NavigationOptionsHeader"
import { SagaAPI } from "@planckdata/api"
import { GeoResults, Images, Reviews, SearchResults } from "@planckdata/api"
import times from "lodash/times"
import { fetchWrapper } from "../../../helpers/fetchHelpers"
import { setUrlParam, useFirstRender } from "../../../helpers/helpers"
import useTrackEvents from "helpers/track-events.hook"

export type SearchResultsWithCount = SearchResults & { count: number }

const useStyles = makeStyles(() => ({
  container: {
    height: "100vh",
    width: "100vw",
    overflow: "hidden",
  },
  headerContainer: {
    height: "50px",
    width: "100%",
    marginTop: "45px",
    marginLeft: "65px",
  },
  navbarContainer: {
    height: "40px",
    width: "100%",
    marginTop: "35px",
    marginLeft: "65px",
  },
  resultsContainer: {
    width: "100%",
    height: "calc(100% - 50px - 45px - 40px - 35px)",
  },
  error: {
    color: colors.error,
  },
}))

export interface ResultsPageProps {}

export const ResultsPage: React.FC<ResultsPageProps> = () => {
  const classes = useStyles({})
  const { Track, trackFilterChange } = useTrackEvents({ page: "Main Page" })
  const urlParams = new URLSearchParams(window.location.search)
  const { getSearchResults, getGeoSearchResults, getSearchReviews, getSearchImages, getFilterOptions } = SagaAPI

  const [currentNavigation, setCurrentNavigation] = useState<NavigationSingleOption>(navigationsOptions[0])
  const [error, setError] = useState<boolean | any>(false)

  const [results, setResults] = useState<SearchResultsWithCount | null>(null)
  const [geoResults, setGeoResults] = useState<GeoResults | null>(null)
  const [reviews, setReviews] = useState<Reviews | null>(null)
  const [images, setImages] = useState<Images | null>(null)
  const [fetchedImagesSources, setFetchedImagesSources] = useState<boolean>(false)

  const [resultsError, setResultsError] = useState<boolean>(false)
  const [geoResultsError, setGeoResultsError] = useState<boolean>(false)
  const [reviewsError, setReviewsError] = useState<boolean>(false)
  const [imagesError, setImagesError] = useState<boolean>(false)

  const [filters, setFilters] = useState<any>(null)
  const [userFilter, setUserFilter] = useState<string | null>(urlParams.get("riskGroupFilter") || null)

  const businessName = urlParams.get("name") || ""
  const businessAddress = urlParams.get("address") || ""
  const searchId = urlParams.get("searchId") || ""
  const pageRoute = urlParams.get("p") || ""
  const firstRender = useFirstRender()

  const loadingMap = {
    all: results === null,
    map: geoResults === null,
    governmentalSources: results?.searchResults === undefined,
    reviews: reviews === null,
    images: images === null,
  }

  const loading = loadingMap[currentNavigation.pageRoute as keyof typeof loadingMap] as boolean

  useEffect(() => {
    onNavigationSelection(navigationsOptions.find((option) => option.pageRoute == pageRoute) || navigationsOptions[0])
    fetchWrapper(getFilterOptions, setFilters, 0, setError)
    fetchWrapper(() => getSearchResults(searchId, userFilter || undefined), setResultsAndFetch, 0, setResultsError)
  }, [])

  useEffect(() => {
    if (firstRender) {
      fetchWrapper(() => getSearchReviews(searchId), setReviews, 0, setReviewsError)
    } else if (!reviews?.isCompleted) {
      setTimeout(() => fetchWrapper(() => getSearchReviews(searchId), setReviews, 0, setReviewsError), 2000)
    }
  }, [reviews])

  useEffect(() => {
    if (firstRender) {
      fetchWrapper(() => getSearchImages(searchId), setImages, 0, setImagesError)
    } else if (!images?.isCompleted) {
      setTimeout(() => fetchWrapper(() => getSearchImages(searchId), setImages, 0, setImagesError), 2000)
    } else if (!fetchedImagesSources) {
      fetchAllPhotoCacheUrlImages()
    }
  }, [images])

  useEffect(() => {
    if (firstRender) {
      fetchWrapper(() => getGeoSearchResults(searchId), setGeoResults, 0, setGeoResultsError)
    } else if (!geoResults?.isCompleted) {
      setTimeout(() => fetchWrapper(() => getGeoSearchResults(searchId), setGeoResults, 0, setGeoResultsError), 2000)
    }
  }, [geoResults])

  function search(userFilterValue = userFilter) {
    setResults(null)
    setUrlParam("riskGroupFilter", userFilterValue)
    fetchWrapper(() => getSearchResults(searchId, userFilterValue || undefined), setResultsAndFetch, 0, setResultsError)
  }

  function onUserFilterChange(newUserFilter: string | null) {
    setUserFilter(newUserFilter)
    search(newUserFilter)
    trackFilterChange(newUserFilter || "")
  }

  function setResultsAndFetch(newResults: SearchResults | null) {
    setResults(
      newResults
        ? {
            ...newResults,
            count:
              (newResults.searchResults.length ?? 0) >= (results?.count ?? 0)
                ? newResults.searchResults.length ?? 0
                : results?.count ?? 0,
          }
        : results,
    )
    if (!newResults?.isCompleted) {
      setTimeout(
        () =>
          fetchWrapper(
            () => getSearchResults(searchId, userFilter || undefined),
            setResultsAndFetch,
            0,
            setResultsError,
          ),
        2000,
      )
    }
  }

  function fetchAllPhotoCacheUrlImages() {
    if (images) {
      setFetchedImagesSources(true)
      const fetchBulk = 5

      for (let loopIndex = 0; loopIndex < images.images.length / fetchBulk; loopIndex += 1) {
        const imagesPromisesArr = times(fetchBulk, (indexInsideLoop) => {
          const index = loopIndex * fetchBulk + indexInsideLoop
          if (images.images[index]) {
            return SagaAPI.getPhotoCacheUrlImage(images.images[index].url, 7000)
              .then((singleSrc: string) => {
                images.images[index].imageSourceString = singleSrc
                setImages(JSON.parse(JSON.stringify(images)))
              })
              .catch(() => {
                images.images[index].error = true
                setImages(JSON.parse(JSON.stringify(images)))
              })
          }
        })
        Promise.all(imagesPromisesArr || [])
      }
    }
  }

  function setUrlParameter(key: string, value: string) {
    urlParams.set(key, value)
    window.history.replaceState(
      null,
      "Saga",
      window.location.origin + window.location.pathname + "?" + urlParams.toString(),
    )
  }

  function onNavigationSelection(newNavigation: NavigationSingleOption) {
    setUrlParameter("p", newNavigation.pageRoute)
    setCurrentNavigation(newNavigation)
  }

  return (
    <Track>
      <Grid container direction={"column"} className={classes.container}>
        <Grid className={classes.headerContainer}>
          <Header
            businessAddress={businessAddress}
            businessName={businessName}
            allFilters={filters?.riskGroups || []}
            setUserFilter={onUserFilterChange}
            userFilter={userFilter}
            search={search}
          />
        </Grid>
        <Grid container direction="row" className={classes.navbarContainer}>
          <NavigationOptionsHeader setCurrentNavigation={onNavigationSelection} currentNavigation={currentNavigation} />
        </Grid>
        <div className={classes.resultsContainer}>
          {error ? (
            <Typography size={15} lineHeight={20} className={classes.error}>
              {error.message}
            </Typography>
          ) : (
            React.createElement(currentNavigation.component, {
              results,
              geoResults,
              businessName,
              businessAddress,
              searchId,
              reviews,
              images,
              resultsError,
              geoResultsError,
              reviewsError,
              imagesError,
              loading,
            })
          )}
        </div>
      </Grid>
    </Track>
  )
}

export default ResultsPage
