import LocationMarker from "../assets/location-marker.png"
import HospitalMarker from "../assets/hospital-marker.png"
import FireStationMarker from "../assets/fire-station-marker.png"
import PoliceStationMarker from "../assets/police-station-marker.png"

import PoliceStationIcon from "../assets/police-station-icon.png"
import FireStationIcon from "../assets/fire-station-icon.png"
import HospitalIcon from "../assets/hospital-icon.png"

import toNumber from "lodash/toNumber"
import { LatLon, POIType } from "@planckdata/api"

export const poisMarkers = {
  [POIType.MAIN_ADDRESS]: LocationMarker,
  [POIType.HOSPITAL]: HospitalMarker,
  [POIType.FIRE_STATION]: FireStationMarker,
  [POIType.POLICE_STATION]: PoliceStationMarker,
}
export const poisIcons = {
  [POIType.HOSPITAL]: HospitalIcon,
  [POIType.FIRE_STATION]: FireStationIcon,
  [POIType.POLICE_STATION]: PoliceStationIcon,
}

export interface LatLng {
  lat: number
  lng: number
}

export function convertLocationToGoogleLocation(location: LatLon): LatLng {
  return { lat: toNumber(location.lat), lng: toNumber(location.lon) }
}
