import React from "react"
import { useTranslation } from "../../../i18n"
import { alpha, colors, makeStyles, typography } from "@planckdata/react-components"
import { ArrowDownIcon } from "@planckdata/react-components/components/icons"
import { useUser } from "user-context"
import { ResultFeedback } from "./ResultFeedback"
import { Routes } from "../../../router"
import { encryptStr } from "../../../helpers/helpers"
import useTrackEvents from "helpers/track-events.hook"

export interface SingleResultProps {
  title: string
  description: string
  url: string
  data: any
  sources: Array<any>
  isUnique: boolean
  index: number
}

const useStyles = makeStyles({
  root: {
    marginBottom: 35,
    maxWidth: 548,
    width: 548,
  },
  urlContainer: {
    height: 26,
    display: "flex",
    alignItems: "center",
    ...typography({ size: 12, lineHeight: 22 }),
    letterSpacing: -0.41,
    color: "#333333",
  },
  urlWrapper: {
    maxWidth: "calc(100% - 76px)",
    display: "flex",
    textDecoration: "none",
    color: "inherit",
  },
  url: {
    maxWidth: "calc(100% - 16px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  urlArrow: {
    transform: "rotate(-90deg)",
    height: 15,
    width: 15,
    marginTop: 3,
  },
  uniqueBadge: {
    background: alpha("#F2F2F2", 0.6),
    borderRadius: 24,
    padding: "2px 8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "uppercase",
    ...typography({ size: 10, weight: 600, lineHeight: 22 }),
    color: "#4F4F4F",
    marginLeft: 20,
  },
  title: {
    ...typography({ size: 18, lineHeight: 22 }),
    textDecoration: "none",
    color: colors.primary,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  description: {
    ...typography({ size: 14, lineHeight: 22 }),
    display: "-webkit-box",
    lineClamp: 2,
    boxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
})

export const SingleResult: React.FC<SingleResultProps> = (props) => {
  const classes = useStyles(props)
  const { title, description, url, data, sources, isUnique, index } = props
  const { t } = useTranslation()
  const { trackSourceFeedback, trackResultUrlEnter } = useTrackEvents()
  const user = useUser()
  const repoUrl = "XXXX"
  const isRepo = url === repoUrl
  const urlParams = new URLSearchParams(window.location.search)
  const searchId = urlParams.get("searchId") || ""

  const [feedbackSubmitted, setFeedbackSubmitted] = React.useState(false)

  const href = url && url === "XXXX" ? getRepoUrl() : url
  const urlText = isRepo ? t("single_result.repo_title_public_documentation") : url

  function handleFeedback(helpful: boolean) {
    setFeedbackSubmitted(true)
    trackSourceFeedback({ helpful, user: user.current!, sources, url, isUnique, isRepo, index })
  }

  function getRepoUrl() {
    return `${window.location.origin}${Routes.SingleRepo}?searchId=${searchId}&id=${encryptStr(JSON.stringify(data))}`
  }

  function handleTitleClick() {
    trackResultUrlEnter({ user: user.current!, sources, url, isUnique, isRepo, index })
  }

  return (
    <div data-testid="SagaResult" className={classes.root}>
      <div className={classes.urlContainer}>
        <a className={classes.urlWrapper} href={href} target="_blank" rel="noreferrer" title={urlText}>
          <div className={classes.url}>{urlText}</div>
          <ArrowDownIcon className={classes.urlArrow} />
        </a>
        {isUnique && <div className={classes.uniqueBadge}>{t("single_result.unique_badge_text")}</div>}
      </div>
      <a className={classes.title} href={href} target="_blank" rel="noreferrer" onClick={handleTitleClick}>
        {title}
      </a>
      <div className={classes.description}>{description}</div>
      <ResultFeedback onFeedback={handleFeedback} submitted={feedbackSubmitted} isRepo={isRepo} />
    </div>
  )
}
